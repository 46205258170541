import { HttpClient } from '@angular/common/http';
import { Inject, Injectable } from '@angular/core';
import { PolicyCoverCategory } from '@features/policies/enums/policy-cover-category.enum';
import { BehaviorSubject, Observable, Subject } from 'rxjs';
import { PolicyCanChangeCover } from '../../policies/interfaces/policy-can-change-cover.interface';
import {
  BuildSettings,
  BUILD_SETTINGS,
} from '@core/configuration/tokens/build-settings.token';
import { ChangeCoverQuote } from '../interfaces/change-cover-quote';
import { Treatment } from '../interfaces/treatment.interface';
import { DogTreatment } from '../interfaces/dog-treatment.interface';
import { CoverType } from '../enums/cover-type.enum';
import { ProductType } from '../enums/product-type.enum';
import { ProductChoice } from '../enums/product-choice.enum';
import { Policy } from '@interfaces/policy';
import { ChangeCoverJourney } from '../enums/change-cover-journey.enum';
import { PolicyMethods } from '@features/policies/services/policy-methods.service';
import { PolicyPaymentFrequency } from '@features/policies/enums/policy-payment-frequency.enum';
import { CoverChangeType } from '../enums/cover-change-type.enum';
import { ProductChange } from '../enums/product-change.enum';
import { TermsTranslationKeys } from '../constants/terms-translation-keys.constant';
import { LimitType } from '../enums/limit-type.enum';
import { ExcessChange } from '../enums/excess-change.enum';
import { CoverItem } from '@interfaces/cover-item';
import { PolicySummary } from '@interfaces/policy-summary.interface';

@Injectable({
  providedIn: 'root',
})
export class ChangeCoverService {
  private policiesBaseURL = `${this.buildSettings.POLICY_API}/v1`;
  private masterDataAPIBaseURL = `${this.buildSettings.MASTERDATA_API}/v1`;

  constructor(
    private http: HttpClient,
    @Inject(BUILD_SETTINGS) private buildSettings: BuildSettings,
    private policyMethods: PolicyMethods,
  ) { }

  private readonly isChangingCover = new BehaviorSubject<boolean>(false);
  readonly isChangingCover$ = this.isChangingCover.asObservable();

  private readonly editPetDetailsClickedSubject = new Subject();
  readonly editPetDetailsClicked$ = this.editPetDetailsClickedSubject.asObservable();

  editPetDetailsClicked() {
    this.editPetDetailsClickedSubject.next();
  }

  setIsChangingCover(isChangingCover: boolean) {
    this.isChangingCover.next(isChangingCover);
  }

  toggleIsChangingCover() {
    this.isChangingCover.next(!this.isChangingCover.getValue());
  }

  getChangeOfCoverEditState(
    canChangeCover: PolicyCanChangeCover,
    isInRenewalWindow: boolean,
    policy: Policy,
    annualCoverMTAEnabled: boolean,
  ){

    if (canChangeCover.hasExceedMaxNumberOfChanges && !isInRenewalWindow) {
      return ChangeOfCoverEditState.MaxChangesExceeded;
    }

    if (canChangeCover.hasExceedMaxNumberOfChanges && isInRenewalWindow) {
      return ChangeOfCoverEditState.MaxChangesExceededInRenewal;
    }

    if (policy.paymentFrequency === PolicyPaymentFrequency.Annually
      && !isInRenewalWindow
      && !annualCoverMTAEnabled) {
      return ChangeOfCoverEditState.Contact;
    }
    
    if (!canChangeCover.hasValidAddress) {
      return ChangeOfCoverEditState.InvalidAddress;
    }

    if (canChangeCover.hasMissedPayment) {
      return ChangeOfCoverEditState.MissedPayment;
    }

    if (canChangeCover.noUpgradeOptions) {
      return ChangeOfCoverEditState.NoUpgradeOptions;
    }

    return ChangeOfCoverEditState.Editing;
  }

  getCardClassByCoverCategory(coverType: string) {
    switch (coverType) {
      case PolicyCoverCategory.Accident:
        return 'accident';
      case PolicyCoverCategory.TimeLimited:
        return 'time-limited';
      case PolicyCoverCategory.MaxBenefit:
        return 'max-benefit';
      case PolicyCoverCategory.Lifetime:
        return 'lifetime';
      default:
        return '';
    }
  }

  getProductChoice(productType: ProductType, coverType: CoverType): ProductChoice {
    if (coverType === CoverType.AccidentOnly) {
      return ProductChoice.AccidentOnly;
    }
    switch (productType) {
      case ProductType.MaxBenefit:
        return ProductChoice.MaxBenefit;
      case ProductType.Timelimited:
        return ProductChoice.Timelimited;
      case ProductType.Lifetime:
        return ProductChoice.Lifetime;
      default:
        return null;
    }
  }

  getChangeCoverQuotes(policyUniqueRef: string): Observable<ChangeCoverQuote[]> {
    return this.http.get<ChangeCoverQuote[]>(
      `${this.policiesBaseURL}/change-cover/${policyUniqueRef}`);
  }

  getTreatmentDataForCat(): Observable<Treatment[]> {
    return this.http.get<Treatment[]>(`${this.masterDataAPIBaseURL}/breed/cat/treatment`);
  }
  
  getTreatmentDataForDog(): Observable<DogTreatment[]> {
    return this.http.get<DogTreatment[]>(`${this.masterDataAPIBaseURL}/breed/dog/treatment`);
  }

  getChangeEffectiveFromDate(policy: Policy) {
    if (!this.policyMethods.isMidTerm(policy)) {
      return new Date(policy.inceptionDate);
    } else {
      return this.policyMethods.getEffectiveDateForMTA(new Date(policy.inceptionDate).getDate());
    }
  }

  getMonthlyChangeCoverJourney(expectedPayment: number, forceRefund: boolean) {
    if (forceRefund) {
      return ChangeCoverJourney.MonthlyRefund;
    } else if (expectedPayment === 0) {
      return ChangeCoverJourney.NoPayment;
    } else {
      return ChangeCoverJourney.Monthly;
    }
  }

  getAnnualChangeCoverJourney(policy: Policy, expectedPayment: number) {
    if (
      expectedPayment === 0 ||
      this.willAnnualCustomersImpendingRenewalPaymentBeTakenAutomatically(policy)
    ) {
      return ChangeCoverJourney.NoPayment;
    }

    if (expectedPayment > 0) {
      return ChangeCoverJourney.AnnualPayment;
    }

    if (expectedPayment < 0) {
      return ChangeCoverJourney.AnnualRefund;
    }
  }

  getSuccessMessageText(changeCoverJourney: ChangeCoverJourney, policy: Policy): string {
    if (policy.payments.hasGivenCPAConsent
      && this.policyMethods.isPaymentOutstanding(policy)
      && this.policyMethods.isDueForRenewal(policy)) {
      return 'AUTH.POLICY.CHANGEOFCOVER.REVIEW.SUCCESS.CPAMESSAGE';
    }

    if (changeCoverJourney === ChangeCoverJourney.AnnualRefund
      || changeCoverJourney === ChangeCoverJourney.MonthlyRefund) {
      return 'AUTH.POLICY.CHANGEOFCOVER.REVIEW.SUCCESS.REFUNDMESSAGE';
    }
    
    return 'AUTH.POLICY.CHANGEOFCOVER.REVIEW.SUCCESS.MESSAGE';
  }

  getIPIDUri(quote: ChangeCoverQuote) {
    return quote.documents.filter(document => document.type === 'IPID')[0]?.uri;
  }

  isNoChange(currentPolicy: Policy, newCover: ChangeCoverQuote): boolean {
    return this.isCurrentProduct(currentPolicy, newCover)
      && currentPolicy.excess === newCover.excess;
  }

  isExcessChangeOnly(currentPolicy: Policy, newCover: ChangeCoverQuote): boolean {
    return this.isCurrentProduct(currentPolicy, newCover)
      && currentPolicy.excess !== newCover.excess;
  }

  isCurrentProduct(currentPolicy: Policy, newCover: ChangeCoverQuote) {
    return currentPolicy.productVersion.startsWith(`V${newCover.productVersion}`) 
        && currentPolicy.coverLevel === newCover.productName;
  }

  getShouldBeOfferedRenewalJourney(policy: PolicySummary) {
    return !this.policyMethods.isMidTerm(policy)
      && !this.policyMethods.isNewBusiness(policy)
      && !this.policyMethods.isLimitedAccess(policy)
      && this.policyMethods.isPet(policy)
      && policy.renewal?.accepted === null
      && policy.isLive;
  }

  getCoverChangeType(currentPolicy: Policy, newCover: ChangeCoverQuote): CoverChangeType {
    if (this.isNoChange(currentPolicy, newCover)) {
      return CoverChangeType.NoChange;
    }
    else if (this.isExcessChangeOnly(currentPolicy, newCover)) {
      return CoverChangeType.ExcessOnly;
    }
    else {
      return CoverChangeType.Cover;
    }
  }

  getNewBusinessTermsAndConditions(currentCover: Policy, newCover: ChangeCoverQuote): string {
    const productChange = newCover.productChange;
    const currentExcess = currentCover.excess;
    const newExcess = newCover.excess;
    const hasExcessChanged = currentExcess !== newExcess;

    if (productChange === ProductChange.Current) {
      return TermsTranslationKeys.NewBusinessExcessOnly;
    } else if (productChange === ProductChange.Downgrade) {
      return hasExcessChanged
        ? TermsTranslationKeys.NewBusinessProductDowngradeExcessChange
        : TermsTranslationKeys.NewBusinessProductDowngrade;
    } else if (productChange === ProductChange.Upgrade) {
      return hasExcessChanged
        ? TermsTranslationKeys.NewBusinessProductUpgradeExcessChange
        : TermsTranslationKeys.NewBusinessProductUpgrade;
    }
  }

  getRenewalTermsAndConditions(currentCover: Policy, newCover: ChangeCoverQuote): string {
    const productChange = newCover.productChange;
    const currentExcess = currentCover.excess;
    const newExcess = newCover.excess;
    const hasExcessChanged = currentExcess !== newExcess;

    if (productChange === ProductChange.Current) {
      return TermsTranslationKeys.RenewalExcessOnly;
    } else if (productChange === ProductChange.Downgrade) {
      return hasExcessChanged
        ? TermsTranslationKeys.RenewalProductDowngradeExcessChange
        : TermsTranslationKeys.RenewalProductDowngrade;
    } else if (productChange === ProductChange.Upgrade) {
      return hasExcessChanged
        ? TermsTranslationKeys.RenewalProductUpgradeExcessChange
        : TermsTranslationKeys.RenewalProductUpgrade;
    }
  }

  getMidtermTermsAndConditions(currentCover: Policy, newCover: ChangeCoverQuote): string {
    const productChange = newCover.productChange;
    const currentExcess = currentCover.excess;
    const newExcess = newCover.excess;

    if (productChange === ProductChange.Downgrade && newExcess > currentExcess) {
      return TermsTranslationKeys.MidtermProductDowngradeExcessIncrease;
    } else if (productChange === ProductChange.Downgrade && newExcess < currentExcess) {
      if (this.isLifetimeAnnual(newCover)) {
        return TermsTranslationKeys.MidtermProductDowngradeExcessDecreaseLifetimeAnnual;
      } else {
        return TermsTranslationKeys.MidtermProductDowngradeExcessDecrease;
      }
    } else if (productChange === ProductChange.Downgrade) {
      return TermsTranslationKeys.MidtermProductDowngrade;
    } else if (productChange === ProductChange.Current && newExcess > currentExcess) {
      return TermsTranslationKeys.MidtermExcessIncreaseOnly;
    } else {
      return '';
    }
  }

  isLifetimeAnnual(changeCoverQuote: ChangeCoverQuote) {
    const newCoverVetFees = changeCoverQuote.benefits.find(b => b.name === 'Vet Fees');
    return changeCoverQuote.productType === ProductType.Lifetime
      && newCoverVetFees.childCoverItems.find(v => v.name === LimitType.AnnualPolicyLimit);
  }

  getExcessChange(currentCover: Policy, newCover: ChangeCoverQuote) {
    if (newCover.excess > currentCover.excess) {
      return ExcessChange.Increase;
    } else if (newCover.excess < currentCover.excess) {
      return ExcessChange.Decrease;
    } else {
      return ExcessChange.NoChange;
    }
  }

  isProductV3 = (productVerion: number ) => productVerion === 3;

  getVetFee(benefits: CoverItem[]): CoverItem {
    return benefits?.find(x => x.name === 'Vet Fees')
      .childCoverItems
      .find(child => child.claimFrequency !== null);
  }

  willAnnualCustomersImpendingRenewalPaymentBeTakenAutomatically(
    policy: Policy,
  ): boolean
  {
    return policy.payments.hasGivenCPAConsent
      && this.policyMethods.isInRenewalWindowButBeforeInceptionDate(policy)
      && policy.outstandingPayment > 0;
  }
}

export enum ChangeOfCoverEditState {
  InvalidAddress,
  Editing,
  MissedPayment,
  MaxChangesExceeded,
  MaxChangesExceededInRenewal,
  NoUpgradeOptions,
  Contact,
}
