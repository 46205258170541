import { NgModule } from '@angular/core';
import { RouterModule } from '@angular/router';
import { CommonModule } from '@angular/common';
import { FontAwesomeModule } from '@fortawesome/angular-fontawesome';
import { TranslateModule } from '@ngx-translate/core';
import { InlineSVGModule } from 'ng-inline-svg-2';
import { NavbarContainerComponent } from './navbar-container.component';
import { FeatureContainerModule } from '@shared/feature-flags/containers/feature/feature-container.module';
import { OffersStoreModule } from '@features/offers/store/offers-store.module';
import { LogoutModalModule } from '@core/authentication/components/logout-modal/logout-modal.module';

@NgModule({
  imports: [
    CommonModule,
    FontAwesomeModule,
    TranslateModule,
    RouterModule,
    InlineSVGModule,
    FeatureContainerModule,
    OffersStoreModule,
    LogoutModalModule,
  ],
  declarations: [NavbarContainerComponent],
  exports: [NavbarContainerComponent],
})
export class NavbarContainerModule {}
