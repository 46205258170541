import { Injectable } from '@angular/core';
import {
  ActivatedRouteSnapshot,
  CanActivateChild,
  Router,
  RouterStateSnapshot,
} from '@angular/router';
import { AuthenticationService } from '@core/authentication/services/authentication.service';
import { PolicyService } from '@features/policies/services/policy.service';
import { HubRouterPaths } from '@router/hub-router.paths';

@Injectable({
  providedIn: 'root',
})
export class ClientNumberGuard implements CanActivateChild {
  constructor(
    private authService: AuthenticationService,
    private policyService: PolicyService,
    private router: Router,
  ) {}

  async canActivateChild(
    _childRoute: ActivatedRouteSnapshot,
    state: RouterStateSnapshot,
  ): Promise<boolean> {
    if (state.url === `/${HubRouterPaths.LoggedIn}/awaiting-policies`) {
      return true;
    }

    const account = this.authService.getAccount();

    if (!account) {
      return false;
    }
    
    if (
      !account.extension_clientNumber ||
      account.extension_clientNumber === '0'
    ) {
      const policies = await this.policyService.getPolicies().toPromise();

      if (policies && policies.resultsCount > 0) {
        return true;
      } else {
        this.router.navigate([
          '/',
          HubRouterPaths.LoggedIn,
          'awaiting-policies',
        ]);
        return false;
      }
    }
    return true;
  }
}
