import { Inject, Injectable } from '@angular/core';
import { HubRouterPaths } from '@router/hub-router.paths';
import { LoggedInRouterPaths } from '@router/logged-in/logged-in-router.paths';
import { WindowToken } from '@core/tokens/window.token';
import {
  ClientSettings,
  CLIENT_SETTINGS,
} from '@core/configuration/tokens/client-settings.token';

@Injectable({
  providedIn: 'root',
})
export class Adb2cService {
  readonly redirectUriBase = `${location.protocol}//${
    location.href.replace('https://', '').replace('http://', '').split('/')[0]
  }`;
  readonly redirectUriAccount = `${this.redirectUriBase}/${HubRouterPaths.LoggedIn}/${LoggedInRouterPaths.Account}#password`;

  constructor(
    @Inject(CLIENT_SETTINGS) private clientSettings: ClientSettings,
    @Inject(WindowToken) private window: Window,
  ) {}

  getCodeUrl(command: Adb2cCommands): string {
    return `${this.clientSettings.oAuthIdentity.instance}${this.clientSettings.oAuthIdentity.domain}/oauth2/v2.0/${command}`;
  }

  getAuthorizationUrl(policy: string, redirectUri: string): string {
    return `${this.getCodeUrl(
      Adb2cCommands.Authorize,
    )}?p=${policy}&nonce=defaultNonce&client_id=${
      this.clientSettings.oAuthIdentity.clientId
    }&response_type=id_token&&scope=${this.getScopes()}&redirect_uri=${redirectUri}`;
  }

  clearParams() {
    const urlParams = new URLSearchParams(this.window.location.search);

    for (const key of Object.values(Adb2cQueryParams)) {
      urlParams.delete(key);
    }

    this.window.history.replaceState(
      {},
      document.title,
      this.window.location.pathname + '?' + urlParams.toString(),
    );
  }

  navigateToResetPassword() {
    const url = this.getAuthorizationUrl(
      this.clientSettings.oAuthIdentity.resetPasswordPolicyId,
      this.redirectUriBase,
    );

    this.window.open(url,"_self");
  }

  navigateToChangePassword() {
    const url = this.getAuthorizationUrl(
      this.clientSettings.oAuthIdentity.editProfilePolicyId,
      this.redirectUriAccount,
    );

    this.window.open(url,"_self");
  }

  private getScopes(): string {
    return this.clientSettings.oAuthIdentity.scope.join(' ');
  }
}

export enum Adb2cCommands {
  Token = 'token',
  Authorize = 'authorize',
  Logout = 'logout',
}

export enum Adb2cQueryParams {
  Code = 'code',
  Error = 'error',
  ErrorDesc = 'error_description',
  State = 'state',
}
