import { Injectable } from "@angular/core";
import { Actions, createEffect, ofType } from "@ngrx/effects";
import { map, switchMap } from "rxjs/operators";
import { OffersService } from "../services/offers.service";
import { offersActions } from "./offers.actions";

@Injectable()
export class OffersEffects {
  loadOffers$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(offersActions.loadOffers),
      switchMap(() =>
        this.offersService.getOffers().pipe(
          map((offers) => offersActions.loadOffersSuccess({ offers })),
        )),
    )},
  );

    constructor(private actions$: Actions, private offersService: OffersService) {}
}