import {
  HttpEvent,
  HttpHandler,
  HttpInterceptor,
  HttpRequest,
  HTTP_INTERCEPTORS,
  HttpResponse,
} from '@angular/common/http';
import { Injectable, Provider } from '@angular/core';
import { OAuthResourceServerErrorHandler } from 'angular-oauth2-oidc';
import { Observable } from 'rxjs/Observable';
import { catchError } from 'rxjs/operators';
import { AuthenticationUtilsService } from '../services/authentication-utils.service';

@Injectable()
export class OAuthInterceptor implements HttpInterceptor {
  constructor(
    private errorHandler: OAuthResourceServerErrorHandler,
    private authenticationUtils: AuthenticationUtilsService,
  ) {}

  intercept(
    req: HttpRequest<any>,
    next: HttpHandler,
  ): Observable<HttpEvent<any>> {
    if (!this.authenticationUtils.isAPI(req.url)) {
      return next.handle(req);
    }

    req = this.authenticationUtils.getAuthRequest(req);

    return next
      .handle(req)
      .pipe(catchError((err: unknown) => {
        if (err instanceof HttpResponse){
          return this.errorHandler.handleError(err)
        }
        throw err;
      }));
  }
}

export const oauthInterceptorProvider: Provider = {
  provide: HTTP_INTERCEPTORS,
  useClass: OAuthInterceptor,
  multi: true,
};
