import { HttpErrorResponse } from '@angular/common/http';
import { ErrorHandler, Injectable, Injector } from '@angular/core';
import { ErrorsService } from './errors.service';

@Injectable()
export class HubErrorsHandler implements ErrorHandler {
  constructor(private injector: Injector) {}

  handleError(error: Error | HttpErrorResponse | string): void {
    const errorsService = this.injector.get(ErrorsService);

    errorsService.handleError(
      typeof error === 'string' ? new Error(error) : error,
    );
  }
}
