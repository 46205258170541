import { Observable } from 'rxjs/Observable';
import { Inject, Injectable } from '@angular/core';
import {
  BuildSettings,
  BUILD_SETTINGS,
} from '@core/configuration/tokens/build-settings.token';
import { HttpClient } from '@angular/common/http';
import { PaginatedResults } from '@interfaces/paginated-results';
import { PaginatedQuery } from '@interfaces/paginated-query';
import { PolicyCommunication } from '../interfaces/policy-communication';

@Injectable({
  providedIn: 'root',
})
export class PolicyOtherDocumentsService {
  private policyOtherDocumentsBaseURL = `${this.buildSettings.SUPPORTINGDOCS_API}/v1`;

  constructor(
    private http: HttpClient,
    @Inject(BUILD_SETTINGS) private buildSettings: BuildSettings,
  ) {}

  public getPolicyOtherDocuments(
    policyUniqueRef: string,
    query?: PaginatedQuery,
  ): Observable<PaginatedResults<PolicyCommunication>> {
    return this.http.get<PaginatedResults<PolicyCommunication>>(
      `${this.policyOtherDocumentsBaseURL}/${policyUniqueRef}/History`,
      {
        params: {
          ...(query as any),
        },
      },
    );
  }

  public getPolicyScheduleUri(policyUniqueRef: string): Observable<string> {
    return this.http.get<string>(
      `${this.policyOtherDocumentsBaseURL}/${policyUniqueRef}/Schedule`,
    );
  }

  public getDocumentUri(
    policyUniqueRef: string,
    documentId: number,
  ): Observable<string> {
    return this.http.get<string>(
      `${this.policyOtherDocumentsBaseURL}/${policyUniqueRef}/Download`,
      {
        params: {
          documentId,
        } as any,
      },
    );
  }
}
