import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { EffectsModule } from '@ngrx/effects';
import { MonitoringEffects } from './store/monitoring.effects';

@NgModule({
  declarations: [],
  imports: [CommonModule, EffectsModule.forFeature([MonitoringEffects])],
})
export class MonitoringModule {}
