import { InjectionToken } from '@angular/core';

export interface BuildSettings {
  AppVersion: string;
  API: string;
  POLICY_API: string;
  RECALCULATION_API: string;
  SUPPORTINGDOCS_API: string;
  MASTERDATA_API: string;
  AppInsightsInstrumentationKey: string;
  GTMContainer: string;
  GTMAuth: string;
  GTMPreview: string;
  CLIENT_API: string;
  PARTNERS_API: string;
}

export const BUILD_SETTINGS = new InjectionToken<BuildSettings>(
  '[CONFIG] Build Settings',
);
