import { InjectionToken } from '@angular/core';

declare global {
  interface Window {
    MentionMeFiredTags: object | undefined;
  }
}

export const windowFactory = () => window;

export const WindowToken = new InjectionToken('window', {
  providedIn: 'root',
  factory: windowFactory,
});
