import { NgModule } from '@angular/core';
import { StoreModule } from '@ngrx/store';
import { policyStateName } from './policy.state';
import { policyReducer } from './policy.reducer';
import { PolicyEffects } from './policy.effects';
import { EffectsModule } from '@ngrx/effects';

@NgModule({
  imports: [
    EffectsModule.forFeature(PolicyEffects),
    StoreModule.forFeature(policyStateName, policyReducer),
  ],
})
export class PolicyStoreModule {}