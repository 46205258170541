import { Inject, Injectable } from '@angular/core';
import { ClientSettings, CLIENT_SETTINGS } from '@core/configuration/tokens/client-settings.token';
import { AuthConfig } from 'angular-oauth2-oidc';
@Injectable({
  providedIn: 'root',
})
export class OAuthIdentityService {
  readonly redirectUriBase = `${location.protocol}//${
    location.href.replace('https://', '').replace('http://', '').split('/')[0]
  }`;

  constructor(
    @Inject(CLIENT_SETTINGS) private clientSettings: ClientSettings,
  ) {}

  buildOauthConfig(): AuthConfig {
    return {
      redirectUri: this.redirectUriBase,
      scope: this.getScopes(),
      clientId: this.clientSettings.oAuthIdentity.clientId,
      issuer: this.clientSettings.oAuthIdentity.authority,
      skipIssuerCheck: true,
      responseType: 'code',
      clearHashAfterLogin: true,
      disableAtHashCheck: true,
      showDebugInformation: true,
      strictDiscoveryDocumentValidation: false,
    };
  }

  private getScopes(): string {
    return this.clientSettings.oAuthIdentity.scope.join(' ');
  }
}
