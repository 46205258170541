import { Observable } from 'rxjs/Observable';
import { Inject, Injectable } from '@angular/core';
import {
  BuildSettings,
  BUILD_SETTINGS,
} from '@core/configuration/tokens/build-settings.token';
import { HttpClient, HttpEvent, HttpHeaders } from '@angular/common/http';
import { UploadedDocuments } from '../interfaces/uploaded-document.interface';
import { PaginatedResults } from '@interfaces/paginated-results';
import { PaginatedQuery } from '@interfaces/paginated-query';
import { BehaviorSubject } from 'rxjs';

@Injectable({
  providedIn: 'root',
})
export class SupportingDocumentsService {
  private reFetchSubject = new BehaviorSubject(null);

  private supportingDocumentsBaseURL = `${this.buildSettings.SUPPORTINGDOCS_API}/v1`;

    constructor(
    private http: HttpClient,
    @Inject(BUILD_SETTINGS) private buildSettings: BuildSettings,
  ) { }

  get reFetch() {
    return this.reFetchSubject.asObservable();
  }

  // Gets all supporting documents for a client, clientNo obtained in the backend
  public getUploadedDocuments(
    query?: PaginatedQuery,
  ): Observable<PaginatedResults<UploadedDocuments>> {
    return this.http.get<PaginatedResults<UploadedDocuments>>(
      this.supportingDocumentsBaseURL,
      {
        params: {
          ...(query as any),
        },
      },
    );
  }

  // Gets all supporting documents for a specific policy
  getUploadedDocumentsForPolicy(
    query?: GetSupportingDocumentsQuery,
  ): Observable<PaginatedResults<UploadedDocuments>> {
    return this.http.get<PaginatedResults<UploadedDocuments>>(
      `${this.supportingDocumentsBaseURL}/${query.policyUniqueRef}`,
      {
        params: {
          skip: query.skip,
          top: query.top,
        } as any,
      },
    );
  }

  // Posts supporting documents for a specific policy
  public uploadDocumentsForPolicyNo(
    documentsForUpload: FormData,
  ): Observable<HttpEvent<any>> {
    return this.http
      .post<any>(this.supportingDocumentsBaseURL, documentsForUpload, {
        reportProgress: true,
        observe: 'events',
        headers: new HttpHeaders({
          'Content-Disposition': 'multipart/form-data',
        }),
      })
  }

  public complete () {
    this.reFetchSubject.next(null)
  }
}

export interface GetSupportingDocumentsQuery extends PaginatedQuery {
  policyUniqueRef?: string;
}
