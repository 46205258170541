import { HttpRequest } from '@angular/common/http';
import { Inject, Injectable } from '@angular/core';
import {
  BuildSettings,
  BUILD_SETTINGS,
} from '@core/configuration/tokens/build-settings.token';
import { EnvironmentService } from '@core/environment/services/environment.service';
import { OAuthStorage } from 'angular-oauth2-oidc';

@Injectable({
  providedIn: 'root',
})
export class AuthenticationUtilsService {
  readonly production = this.environmentService.production;

  constructor(
    @Inject(BUILD_SETTINGS) private buildSettings: BuildSettings,
    private authStorage: OAuthStorage,
    private environmentService: EnvironmentService,
  ) {}

  isAPI(url: string): boolean {
    return !this.production
      ? true
      : url.toLowerCase().indexOf(this.buildSettings.API.toLowerCase()) !== -1;
  }

  getAuthRequest(req: HttpRequest<any>): HttpRequest<any> {
    const token = this.authStorage.getItem('access_token');

    if (!token) {
      return req;
    }

    return req.clone({
      setHeaders: {
        Authorization: `Bearer ${token}`,
      },
    });
  }
}
