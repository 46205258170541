import { Claim, ClaimStatus } from '@interfaces/claim';
import { MockPoliciesMap } from './policies';

export const MockRecievedClaim: Claim = {
  claimSubID: 0,
  status: ClaimStatus.Received,
  pet: MockPoliciesMap.LiveCatAccidentOnlyOverduePolicy.pet.petName,
  condition: 'Limp',
  dateReceived: new Date(),
  submittedBy: 'Vet practice name',
  policyUniqueRef:
    MockPoliciesMap.LiveCatAccidentOnlyOverduePolicy.policyUniqueRef,
  amount: 450,
  treatingVetPracticeName: null,
  paymentToGoTo: null,
  isRequest: false,
};

export const MockInProgressClaim: Claim = {
  claimSubID: 1,
  status: ClaimStatus.InProgress,
  pet: MockPoliciesMap.LiveCatOverduePolicy.pet.petName,
  condition: 'Corneal uncer',
  dateReceived: new Date(),
  submittedBy: 'Vet practice name',
  policyUniqueRef: MockPoliciesMap.LiveCatOverduePolicy.policyUniqueRef,
  amount: 300.0,
  treatingVetPracticeName: null,
  paymentToGoTo: null,
  isRequest: false,
};

export const MockAwaitingFromCustomerClaim: Claim = {
  claimSubID: 2,
  status: ClaimStatus.AwaitingFromCustomer,
  pet: MockPoliciesMap.LiveHorsePolicy.pet.petName,
  condition: 'Broken Leg',
  dateReceived: new Date(),
  submittedBy: 'Vet practice name',
  policyUniqueRef: MockPoliciesMap.LiveHorsePolicy.policyUniqueRef,
  amount: 120,
  treatingVetPracticeName: null,
  paymentToGoTo: null,
  isRequest: false,
};

export const MockAcceptedClaim: Claim = {
  claimSubID: 3,
  status: ClaimStatus.Accepted,
  pet: MockPoliciesMap.LiveDogAnnualRenewalDuePolicy.pet.petName,
  condition: 'Broken Foot',
  dateReceived: new Date(),
  submittedBy: 'Vet practice name',
  policyUniqueRef:
    MockPoliciesMap.LiveDogAnnualRenewalDuePolicy.policyUniqueRef,
  amount: 200,
  treatingVetPracticeName: null,
  paymentToGoTo: null,
  isRequest: false,
};

export const MockAbeyanceClaim: Claim = {
  claimSubID: 4,
  status: ClaimStatus.Abeyance,
  pet: MockPoliciesMap.LiveDogAnnualRenewalDuePolicy.pet.petName,
  condition: 'Broken tail',
  dateReceived: new Date(),
  submittedBy: 'Vet practice name',
  policyUniqueRef:
    MockPoliciesMap.LiveDogAnnualRenewalDuePolicy.policyUniqueRef,
  amount: 1400,
  treatingVetPracticeName: null,
  paymentToGoTo: null,
  isRequest: false,
};

export const MockAwaitingFromVetClaim: Claim = {
  claimSubID: 5,
  status: ClaimStatus.AwaitingFromVet,
  pet: MockPoliciesMap.LiveDogAnnualRenewalDuePolicy.pet.petName,
  condition: 'Broken foot',
  dateReceived: new Date(),
  submittedBy: 'Vet practice name',
  policyUniqueRef:
    MockPoliciesMap.LiveDogAnnualRenewalDuePolicy.policyUniqueRef,
  amount: 2000,
  treatingVetPracticeName: null,
  paymentToGoTo: null,
  isRequest: false,
};

export const MockAwaitingFromCustomerAndVetClaim: Claim = {
  claimSubID: 6,
  status: ClaimStatus.AwaitingFromCustomerAndVet,
  pet: MockPoliciesMap.LiveCatOverduePolicy.pet.petName,
  condition: 'Broken leg',
  dateReceived: new Date(),
  submittedBy: 'Vet practice name',
  policyUniqueRef: MockPoliciesMap.LiveCatOverduePolicy.policyUniqueRef,
  amount: 1900,
  treatingVetPracticeName: null,
  paymentToGoTo: null,
  isRequest: false,
};

export const MockDeclinedClaim: Claim = {
  claimSubID: 7,
  status: ClaimStatus.Declined,
  pet: MockPoliciesMap.LiveDogAnnualRenewalDuePolicy.pet.petName,
  condition: 'Broken Paw',
  dateReceived: new Date(),
  submittedBy: 'Vet practice name',
  policyUniqueRef:
    MockPoliciesMap.LiveDogAnnualRenewalDuePolicy.policyUniqueRef,
  amount: 450,
  treatingVetPracticeName: null,
  paymentToGoTo: null,
  isRequest: false,
};

export const MockDeclinedRiderClaim: Claim = {
  claimSubID: 8,
  status: ClaimStatus.Declined,
  pet: 'Peter Spladge',
  condition: 'Broken leg',
  dateReceived: new Date(),
  submittedBy: 'Horse Vet Ltd',
  policyUniqueRef: MockPoliciesMap.LiveRiderMonthlyPolicy.policyUniqueRef,
  amount: 149.99,
  treatingVetPracticeName: null,
  paymentToGoTo: null,
  isRequest: false,
};

export const MockVetFeeClaim: Claim = {
  claimSubID: 9,
  status: ClaimStatus.PassedToVet,
  pet: 'Vet Fee Pet',
  condition: 'Broken leg',
  dateReceived: new Date(),
  submittedBy: 'Customer',
  policyUniqueRef:
    MockPoliciesMap.LiveDogAnnualRenewalDuePolicy.policyUniqueRef,
  amount: 150,
  treatingVetPracticeName: 'test vet',
  paymentToGoTo: 'Policyholder',
  isRequest: true,
};

export const MockClaimsMap = {
  MockRecievedClaim,
  MockInProgressClaim,
  MockAwaitingFromCustomerClaim,
  MockAwaitingFromCustomerAndVetClaim,
  MockAwaitingFromVetClaim,
  MockAcceptedClaim,
  MockAbeyanceClaim,
  MockDeclinedClaim,
  MockDeclinedRiderClaim,
  MockVetFeeClaim,
};

export const MockAllClaims: Claim[] = Object.values(MockClaimsMap);
