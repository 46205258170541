import { HttpClient } from "@angular/common/http";
import { Inject, Injectable } from "@angular/core";
import { BuildSettings, BUILD_SETTINGS } from "@core/configuration/tokens/build-settings.token";
import { Observable } from "rxjs";
import { AlgorithmCategory } from "../interfaces/algorithm-category";
import { StartSessionResponse } from "../interfaces/start-session-response";
import { StartSessionRequest } from "../interfaces/start-session-request";
import { GetNextNodeRequest } from "../interfaces/get-next-node-request";
import { GetNextNodeResponse } from "../interfaces/get-next-node-response";
import { GetPreviousNodeResponse } from "../interfaces/get-previous-node-response";

@Injectable({
  providedIn: 'root',
})
export class JoiiService {
  private partnersBaseUrl = `${this.buildSettings.PARTNERS_API}/v1`;

  constructor(
    private http: HttpClient,
    @Inject(BUILD_SETTINGS) private buildSettings: BuildSettings,
  ) {}

  getAlgorithmCategories(policyUniqueRef: string): Observable<AlgorithmCategory[]> {
    return this.http.get<AlgorithmCategory[]>(
      `${this.partnersBaseUrl}/joii/symptom-checker/${policyUniqueRef}/categories`);
  }

  startSession(
    policyUniqueRef: string,
    startSessionRequest: StartSessionRequest,
  ): Observable<StartSessionResponse> {
    return this.http.post<StartSessionResponse>(
      `${this.partnersBaseUrl}/joii/symptom-checker/${policyUniqueRef}/session`,
      {
        weight: startSessionRequest.weight,
        neutered: startSessionRequest.isNeutered,
        algorithmCategoryDocId: startSessionRequest.algorithmCategoryDocId,
      },
    );
  }

  getNextNode(
    policyUniqueRef: string,
    sessionId: string,
    getNextNodeRequest: GetNextNodeRequest): Observable<GetNextNodeResponse>{
    return this.http.post<GetNextNodeResponse>(
      `${this.partnersBaseUrl}/joii/symptom-checker/${policyUniqueRef}/${sessionId}/next`,
      getNextNodeRequest,
    );
  }

  getPreviousNode(
    policyUniqueRef: string,
    sessionId: string): Observable<GetPreviousNodeResponse>{
    return this.http.post<GetPreviousNodeResponse>(
      `${this.partnersBaseUrl}/joii/symptom-checker/${policyUniqueRef}/${sessionId}/previous`,
      {},
    );
  }
}
