import { 
  CancellationProviders,
} from "@features/policies/interfaces/cancellation-providers.interface";
import { CancellationReason } from "@features/policies/interfaces/cancellation-reason.interface";
import { DiscountResponse } from "@features/policies/interfaces/discount-response.interface";

export const RenewalStateName = 'Renewal';

export interface RenewalState {
  usingJourney: boolean;
  dismissed: boolean;
  journeyComplete: boolean;
  changes: string[];
  isConfirmationRequired: boolean;
  discount: DiscountResponse;
  discountAccepted: boolean;
  cancellationReasons: CancellationReason[];
  cancellationProviders: CancellationProviders[];
  isApplyDiscountInProgress: boolean;
}

export const initialRenewalState: RenewalState = {
  usingJourney: false,
  dismissed: false,
  journeyComplete: false,
  changes: [],
  isConfirmationRequired: false,
  discount: null,
  discountAccepted: false,
  cancellationReasons: [],
  cancellationProviders: [],
  isApplyDiscountInProgress: false,
};