export enum AddressEditState {
  TypeaheadEdit,
  ManualEdit,
  MonthlyReview,
  AnnualReview,
  ChangeNotAllowed,
  PostCodeNotAllowed,
  Loading,
  Error,
  Success,
}
