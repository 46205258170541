import { HttpClient } from '@angular/common/http';
import { Inject, Injectable } from '@angular/core';
import { BUILD_SETTINGS, BuildSettings } from '@core/configuration/tokens/build-settings.token';
import { CancellationReason } from '../interfaces/cancellation-reason.interface';
import { CancellationProviders } from '../interfaces/cancellation-providers.interface';
import { CancellationResponse } from '../interfaces/cancellation-response.interface';

@Injectable({
    providedIn: 'root',
})
export class CancellationService
{
    readonly masterDataAPIBaseURL = `${this.buildSettings.MASTERDATA_API}/v1`;
    readonly policiesBaseURL = `${this.buildSettings.POLICY_API}/v1`;

    constructor(
      @Inject(BUILD_SETTINGS) private buildSettings: BuildSettings,
      private http: HttpClient,
    ) {}

    getCancellationReasons(riskType = 'Pet') {
        return this.http.get<CancellationReason[]>(`${this.masterDataAPIBaseURL}/cancellation/reasons/${riskType}`);
    } 

    getInsuranceProviders() {
        return this.http.get<CancellationProviders[]>(`${this.masterDataAPIBaseURL}/cancellation/providers`);
    }

    cancelPolicy(policyNo: string, reason: string, provider: string, comment: string) {
        return this.http.post<CancellationResponse>(`${this.policiesBaseURL}/${policyNo}/cancel`, { reason, provider, comment });
    } 
}