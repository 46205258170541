<div data-e2e="vet-fees-example-v3-claim-summary" [innerHTML]="'AUTH.POLICY.CHANGEOFCOVER.REVIEW.LOWCOVERPRODUCT.V3.EXAMPLE.CLAIMEXAMPLESUMMARY.' + (productName | removeWhitespace | uppercase) | translate"></div>
<div class="row">
  <div class="col-7 col-lg-5" translate>AUTH.POLICY.CHANGEOFCOVER.REVIEW.LOWCOVERPRODUCT.V3.EXAMPLE.NOCOPAYMENT.AMOUNT</div>
  <div class="col-5 col-lg-7" translate>AUTH.POLICY.CHANGEOFCOVER.REVIEW.LOWCOVERPRODUCT.V3.EXAMPLE.NOCOPAYMENT.AMOUNTVALUE</div>
</div>
<div class="row mb-2 d-flex">
  <div class="col-7 col-lg-5 fw-bold" translate>AUTH.POLICY.CHANGEOFCOVER.REVIEW.LOWCOVERPRODUCT.V3.EXAMPLE.NOCOPAYMENT.ANIMALFRIENDS</div>
  <div data-e2e="vet-fees-example-v3-animal-friends-value" class="col-5 col-lg-7 align-self-end" [innerHTML]="'AUTH.POLICY.CHANGEOFCOVER.REVIEW.LOWCOVERPRODUCT.V3.EXAMPLE.NOCOPAYMENT.ANIMALFRIENDSVALUE.' + (productName | removeWhitespace | uppercase) | translate"></div>
</div>
<div class="row">
  <div class="col fw-bold" translate>AUTH.POLICY.CHANGEOFCOVER.REVIEW.LOWCOVERPRODUCT.V3.EXAMPLE.NOCOPAYMENT.PAYTOVET</div>
</div>
<div class="row">
  <div class="col-7 col-lg-5" translate>AUTH.POLICY.CHANGEOFCOVER.REVIEW.LOWCOVERPRODUCT.V3.EXAMPLE.NOCOPAYMENT.EXCESS</div>
  <div class="col-5 col-lg-7" translate>AUTH.POLICY.CHANGEOFCOVER.REVIEW.LOWCOVERPRODUCT.V3.EXAMPLE.NOCOPAYMENT.EXCESSVALUE</div>
</div>
<div class="row mb-3">
  <div class="col-7 col-lg-5" translate>AUTH.POLICY.CHANGEOFCOVER.REVIEW.LOWCOVERPRODUCT.V3.EXAMPLE.NOCOPAYMENT.REMAINDER</div>
  <div class="col-5 col-lg-7" translate>AUTH.POLICY.CHANGEOFCOVER.REVIEW.LOWCOVERPRODUCT.V3.EXAMPLE.NOCOPAYMENT.REMAINDERVALUE</div>
</div>
<div class="row mb-4">
  <div class="col-7 col-lg-5 fw-bold" translate>AUTH.POLICY.CHANGEOFCOVER.REVIEW.LOWCOVERPRODUCT.V3.EXAMPLE.NOCOPAYMENT.YOURPAYMENT</div>
  <div class="col-5 col-lg-7" translate>AUTH.POLICY.CHANGEOFCOVER.REVIEW.LOWCOVERPRODUCT.V3.EXAMPLE.NOCOPAYMENT.YOURPAYMENTVALUE</div>
</div>
<div class="row mb-4">
  <div data-e2e="vet-fees-example-v3-nocopayment-scenario-text" class="col" [innerHTML]="'AUTH.POLICY.CHANGEOFCOVER.REVIEW.LOWCOVERPRODUCT.V3.EXAMPLE.NOCOPAYMENT.SCENARIOTEXT.' + (productName | removeWhitespace | uppercase) | translate"></div>
</div>
<p class="fw-bold mb-4" translate>
  AUTH.POLICY.CHANGEOFCOVER.REVIEW.LOWCOVERPRODUCT.V3.EXAMPLE.COPAYMENT.SUMMARY
</p>
<div class="row">
  <div class="col-7 col-lg-5" translate>AUTH.POLICY.CHANGEOFCOVER.REVIEW.LOWCOVERPRODUCT.V3.EXAMPLE.COPAYMENT.AMOUNT</div>
  <div class="col-5 col-lg-7" translate>AUTH.POLICY.CHANGEOFCOVER.REVIEW.LOWCOVERPRODUCT.V3.EXAMPLE.COPAYMENT.AMOUNTVALUE</div>
</div>
<div class="row mb-2 d-flex">
  <div class="col-7 col-lg-5 fw-bold" translate>AUTH.POLICY.CHANGEOFCOVER.REVIEW.LOWCOVERPRODUCT.V3.EXAMPLE.COPAYMENT.ANIMALFRIENDS</div>
  <div class="col-5 col-lg-7 align-self-end" translate>AUTH.POLICY.CHANGEOFCOVER.REVIEW.LOWCOVERPRODUCT.V3.EXAMPLE.COPAYMENT.ANIMALFRIENDSVALUE</div>
</div>
<div class="row">
  <div class="col fw-bold" translate>AUTH.POLICY.CHANGEOFCOVER.REVIEW.LOWCOVERPRODUCT.V3.EXAMPLE.COPAYMENT.PAYTOVET</div>
</div>
<div class="row">
  <div class="col-7 col-lg-5" translate>AUTH.POLICY.CHANGEOFCOVER.REVIEW.LOWCOVERPRODUCT.V3.EXAMPLE.COPAYMENT.EXCESS</div>
  <div class="col-5 col-lg-7" translate>AUTH.POLICY.CHANGEOFCOVER.REVIEW.LOWCOVERPRODUCT.V3.EXAMPLE.COPAYMENT.EXCESSVALUE</div>
</div>
<div class="row mb-3">
  <div class="col-7 col-lg-5" translate>AUTH.POLICY.CHANGEOFCOVER.REVIEW.LOWCOVERPRODUCT.V3.EXAMPLE.COPAYMENT.COPAYMENT</div>
  <div class="col-5 col-lg-7" translate>AUTH.POLICY.CHANGEOFCOVER.REVIEW.LOWCOVERPRODUCT.V3.EXAMPLE.COPAYMENT.COPAYMENTVALUE</div>
</div>
<div class="row mb-4">
  <div class="col-7 col-lg-5 fw-bold" translate>AUTH.POLICY.CHANGEOFCOVER.REVIEW.LOWCOVERPRODUCT.V3.EXAMPLE.COPAYMENT.YOURPAYMENT</div>
  <div class="col-5 col-lg-7" translate>AUTH.POLICY.CHANGEOFCOVER.REVIEW.LOWCOVERPRODUCT.V3.EXAMPLE.COPAYMENT.YOURPAYMENTVALUE</div>
</div>
<div data-e2e="vet-fees-example-v3-copayment-scenario-text" [innerHTML]="'AUTH.POLICY.CHANGEOFCOVER.REVIEW.LOWCOVERPRODUCT.V3.EXAMPLE.COPAYMENT.SCENARIOTEXT.' + (productName | removeWhitespace | uppercase) | translate"></div>
<p translate>
  AUTH.POLICY.CHANGEOFCOVER.REVIEW.LOWCOVERPRODUCT.V3.EXAMPLE.COPAYMENT.POLICYBOOKLET
</p>