export enum PolicyPaymentType {
  AnnualRenewal = 'AnnualRenewal',
  MissedPayment = 'MissedPayment',
  EarlyPayment = 'EarlyPayment',
  AnnualExcess = 'AnnualExcess',
  GenderChange = 'GenderChange',
  BreedChange = 'BreedChange',
  DOBChange = 'DOBChange',
  CoverChange = 'CoverChange',
  Renewal = 'Renewal',
  None = 'None',
}