import { Inject, Injectable } from '@angular/core';
import { BUILD_SETTINGS, BuildSettings } from '@core/configuration/tokens/build-settings.token';

@Injectable({
  providedIn: 'root',
})
export class AnalyticsService {

  constructor(
    @Inject(BUILD_SETTINGS) private buildSettings: BuildSettings,
  ) {}

  getGtmContainer(): string {
    return this.buildSettings.GTMContainer;
  }

  getGtmAuth(): string {
    return this.buildSettings.GTMAuth;
  }

  getGtmPreview(): string {
    return this.buildSettings.GTMPreview;
  }
}
