import {
  PolicyHolderStateName,
  initialPolicyHolderState,
} from '@features/account-details/store/state';
import { initialChangeCoverState } from '@features/change-cover/store/change-cover-state';
import { CoverSelectionStateName } from '@features/change-cover/store/cover-selection-state';
import { RenewalStateName } from '@features/change-cover/store/renewal.state';
import { UserConsentStateName } from '@features/change-cover/store/user-consent-state';
import { initialPolicyState } from '@features/policies/store/policy.reducer';
import { Action, ActionReducer } from '@ngrx/store';
import { localStorageSync } from 'ngrx-store-localstorage';

const INIT_ACTION = '@ngrx/store/init';
const UPDATE_ACTION = '@ngrx/store/update-reducers';

const mergeReducer = (state: any, rehydratedState: any, action: Action) => {
  if (action.type === INIT_ACTION && rehydratedState) {
    return {
      ...state,
      Policy: {
        ...initialPolicyState,
        ...rehydratedState.Policy,
      },
      Policyholder: {
        ...initialPolicyHolderState,
        ...rehydratedState.Policyholder,
      },
      ChangeCover: {
        ...initialChangeCoverState,
        ...rehydratedState.ChangeCover,
      },
    };
  }

  return state;
};

export const localStorageSyncReducer =
  <T, V extends Action = Action>(reducer: ActionReducer<any>): ActionReducer<T, V> =>
    (state, action) => {
      if (action.type === UPDATE_ACTION) {
        return reducer(state, action);
      }

      return localStorageSync({
        keys: [
          { Policy: ['policy', 'isInRenewalWindow'] },
          {
            ChangeCover: [
              CoverSelectionStateName,
              UserConsentStateName,
              RenewalStateName,
            ],
          },
          PolicyHolderStateName,
        ],
        rehydrate: true,
        mergeReducer,
      })(reducer)(state, action);
    };
