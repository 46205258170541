import { NgModule } from '@angular/core';
import { EffectsModule } from '@ngrx/effects';
import { StoreModule } from '@ngrx/store';
import { PolicyHolderEffects } from './effects/policy-holder.effects';
import { policyHolderReducer } from './reducers/policy-holder.reducer';
import { PolicyHolderStateName } from './state';

@NgModule({
  imports: [
    EffectsModule.forFeature([PolicyHolderEffects]),
    StoreModule.forFeature(PolicyHolderStateName, policyHolderReducer),
  ],
})
export class PolicyHolderModule {}
