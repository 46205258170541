import { NgModule } from '@angular/core';
import { StoreModule } from '@ngrx/store';
import { EffectsModule } from '@ngrx/effects';
import { CoverSelectionEffects } from './effects/cover-selection.effects';
import { rootReducer } from './reducers/change-cover.reducer';
import { ChangeCoverStateName } from './change-cover-state';
import { ReviewCoverEffects } from './effects/review-cover.effects';
import { ChangeCoverEffects } from './effects/change-cover.effects';
import { RenewalEffects } from './effects/renewal.effects';

@NgModule({
  imports: [
    EffectsModule.forFeature([
      ChangeCoverEffects,
      CoverSelectionEffects,
      ReviewCoverEffects,
      RenewalEffects,
    ]),
    StoreModule.forFeature(ChangeCoverStateName, rootReducer),
  ],
})
export class ChangeCoverStoreModule {}
