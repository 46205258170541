import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { HttpClientModule } from '@angular/common/http';
import { InlineSVGModule } from 'ng-inline-svg-2';
import { Rooti18nModule } from './core/i18n/root-i18n.module';

export const ROOT_MODULES = [
  Rooti18nModule,
  HttpClientModule,
  BrowserAnimationsModule,
  InlineSVGModule.forRoot(),
];
