import { NgModule } from '@angular/core';
import { StoreModule } from '@ngrx/store';
import { EffectsModule } from '@ngrx/effects';
import { OffersEffects } from './offers.effects';
import { offersStateName } from './offers.state';
import { offersReducer } from './offers.reducer';

@NgModule({
  imports: [
    EffectsModule.forFeature([OffersEffects]),
    StoreModule.forFeature(offersStateName, offersReducer),
  ],
})
export class OffersStoreModule {}
