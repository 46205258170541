import { PolicyPaymentFrequency } from '@features/policies/enums/policy-payment-frequency.enum';
import { PolicyPaymentStatus } from '@features/policies/enums/policy-payment-status.enum';
import { Policy } from '@interfaces/policy';
import { MockPolicyPaymentsMap } from './policy-payments';

export const MockOldPolicies: Policy[] = [
  {
    policyUniqueRef: 'PD1212121-1',
    previousPolicyRef: undefined,
    coverLevel: 'Accident',
    coverCategory: 'Accident Only',
    status: 'Renewed',
    statusReason: null,
    inceptionDate: new Date(),
    ipidUri: 'http://www.animalfriends.co.uk',
    policyUri: 'http://www.animalfriends.co.uk',
    termsUri: undefined,
    vetFeeCapsUri: 'http://www.animalfriends.co.uk',
    pet: {
      petName: 'Sharron',
      petBreed: 'Moggie',
      petDOB: new Date(new Date().getTime() - 1000 * 60 * 60 * 24 * 365 * 6),
      petColour: 'Red',
      petGender: 'Female',
      microchipNo: '12335645',
      heightHands: null,
      heightInches: null,
      riderDOB: null,
      riderFirstName: null,
      riderSurname: null,
      type: 'cat',
      horseActivity: null,
      horsePostcode: null,
      passportNo: null,
      horseOwnershipStatus: null,
      horsePurchaseDate: null,
    },
    expiryDate: new Date(
      new Date().getTime() + 1000 * 60 * 60 * 24 - 1000 * 60 * 60 * 24 * 365,
    ),
    renewalDate: new Date(
      new Date().getTime() +
        1000 * 60 * 60 * 24 * 152 -
        1000 * 60 * 60 * 24 * 365,
    ),
    coPayment: 0,
    excess: 50,
    monthlyTotal: 20,
    annualTotal: 240,
    outstandingPayment: 0,
    paymentStatus: PolicyPaymentStatus.UpToDate,
    paymentFrequency: PolicyPaymentFrequency.Monthly,
    preferredPaymentDay: 28,
    customerActionRequired: false,
    voluntaryExcess: null,
    productId: null,
    productVersion: 'V1 09162021',
    payments: MockPolicyPaymentsMap.NoPayments,
    isLive: false,
  },
];
