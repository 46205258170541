import { enableProdMode } from '@angular/core';
import { platformBrowserDynamic } from '@angular/platform-browser-dynamic';
import { AppModule } from './app/app.module';
import { environment } from './environments/environment';
import { SilentError } from '@core/error-handling/models/silent.error';
import {
  ClientSettings,
  CLIENT_SETTINGS,
} from '@core/configuration/tokens/client-settings.token';
import {
  BuildSettings,
  BUILD_SETTINGS,
} from '@core/configuration/tokens/build-settings.token';
import { MockClientSettings } from '@testing/data/client-settings';

export const getBaseUrl = () => document.getElementsByTagName('base')[0].href;

if (environment.production) {
  enableProdMode();
}

(async () => {
  const buildSettings: BuildSettings = await (
    await fetch(environment.config)
  ).json();

  if (!buildSettings) {
    throw new Error(`Could not load configuration from ${environment.config}`);
  }

  const clientSettings: ClientSettings = !environment.fakeBackend
    ? await (await fetch(`${buildSettings.API}/`)).json()
    : MockClientSettings;

  if (!clientSettings) {
    throw new Error(`Could not load configuration from ${buildSettings.API}`);
  }

  await platformBrowserDynamic([
    { provide: 'BASE_URL', useFactory: getBaseUrl, deps: [] },
    { provide: CLIENT_SETTINGS, useValue: clientSettings },
    { provide: BUILD_SETTINGS, useValue: buildSettings },
  ]).bootstrapModule(AppModule);
})().catch((err: Error) => {
  if (err instanceof SilentError) {
    if (!environment.production) {
      console.warn(err);
    }
    return;
  }

  console.error(err);
});
