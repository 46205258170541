import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { tap } from 'rxjs/operators';
import { ChangeCoverService } from '@features/change-cover/services/change-cover.service';
import { changeCoverActions } from '../actions/change-cover.actions';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { CopaymentsExplanationModalComponent } from '@features/change-cover/components/copayments-explanation-modal/copayments-explanation-modal.component';
import { ProductType } from '@features/change-cover/enums/product-type.enum';
import { LimitType } from '@features/change-cover/enums/limit-type.enum';

@Injectable()
export class ChangeCoverEffects {
  openCopaymentModal$ = createEffect(
    () => {
      return this.actions$.pipe(
        ofType(changeCoverActions.openCopaymentModal),
        tap(({ policy, productType, productVersion, benefits }) => {
          const vetFee = this.changeCoverService.getVetFee(benefits);
          const modal = this.modalService.open(CopaymentsExplanationModalComponent, { centered: true });
          modal.componentInstance.policy = policy;
          modal.componentInstance.productType = productType;
          modal.componentInstance.productVersion = productVersion;
          modal.componentInstance.limit = vetFee.limit;
          if (productType === ProductType.Lifetime) {
            modal.componentInstance.limitType =
              vetFee.name === LimitType.AnnualConditionLimit
                ? LimitType.AnnualConditionLimit
                : LimitType.AnnualPolicyLimit;
          }
        }),
      );
    },
    { dispatch: false },
  );

  constructor(
    private actions$: Actions,
    private modalService: NgbModal,
    private changeCoverService: ChangeCoverService,
  ) {}
}
