import { createReducer, on } from "@ngrx/store";
import { policyActions } from "./policy.actions";
import { PolicyState } from "./policy.state";
import { PolicyPaymentFrequency } from "../enums/policy-payment-frequency.enum";
import { ExcessEditState, PetDetailsEditState } from "../services/policy-methods.service";
import { coreActions } from "@core/store/actions/core.actions";
import { RenewalActions } from "@features/change-cover/store/actions/renewal.actions";
import { PaymentDayEditState } from "../enums/payment-day-edit-state.enum";

export const initialPolicyState: PolicyState = {
  policy: null,
  previousPolicy: null,
  isInRenewalWindow: null,
  directDebitDetails: null,
  canEditDirectDebitDetails: null,
  shouldChangeDirectDebitDetails: null,
  upcomingPayments: null,
  canChangeCover: null,
  excess: null,
  staticDocuments: null,
  exclusions: null,
  editableFields: null,
  claims: null,
  coverItems: null,
  excessEditState: ExcessEditState.Loading,
  uploadedDocuments: null,
  uploadedDocumentsPageParams: {
    search: '',
    top: 10,
    skip: 0,
    page: 1,
  },
  petDetailsEditState: null,
  canChangePassportNo: null,
  canChangePaymentDay: null,
  paymentDayEditState: PaymentDayEditState.Viewing,
  paymentDayScheduleRecalculation: null,
  newPreferredPaymentDay: null,
  cancellation: null,
  policyDocuments: null,
  cancellationDetails: null,
  horseColours: null,
  policyFinancials: null,
};

export const policyReducer = createReducer(
  initialPolicyState,

  on(
    policyActions.fetchPolicy,
    coreActions.resetStore, 
    (): PolicyState => ({
      ...initialPolicyState,
    })),

  on(policyActions.fetchPolicySuccess, (state, { policy }): PolicyState => ({
    ...state,
    policy,
  })),

  on(policyActions.updatePolicyTotal, (state, { monthlyTotal }): PolicyState => ({
    ...state,
    policy: {
      ...state.policy,
      monthlyTotal,
      annualTotal: monthlyTotal * 12,
    },
  })),

  on(policyActions.fetchDirectDebitDetailsSuccess, 
    (state, { directDebitDetails }): PolicyState => ({
      ...state,
      directDebitDetails,
    })),

  on(policyActions.fetchCanEditDirectDebitDetailsSuccess, 
    (state, { canEditDirectDebitDetails }): PolicyState => ({
      ...state,
      canEditDirectDebitDetails,
    })),

  on(policyActions.fetchShouldChangeDirectDebitDetailsSuccess, 
    (state, { shouldChangeDirectDebitDetails }): PolicyState => ({
      ...state,
      shouldChangeDirectDebitDetails,
    })),

  on(policyActions.updateDirectDebitDetailsSuccess, 
    (state, { directDebitDetails }): PolicyState => ({
      ...state,
      directDebitDetails,
      shouldChangeDirectDebitDetails: false,
      canEditDirectDebitDetails: {
        ...state.canEditDirectDebitDetails,
        canReinstateDirectDebit: false,
      },
    })),

  on(policyActions.reinstateMandateSuccess, (state): PolicyState => ({
    ...state,
    shouldChangeDirectDebitDetails: false,
    canEditDirectDebitDetails: {
      ...state.canEditDirectDebitDetails,
      canReinstateDirectDebit: false,
    },
  })),

  on(policyActions.fetchCanChangeCoverSuccess, (state, { canChangeCover }): PolicyState => ({
    ...state,
    canChangeCover,
  })),

  on(policyActions.fetchUpcomingPaymentsSuccess, (state, { upcomingPayments }): PolicyState => ({
    ...state,
    upcomingPayments,
  })),
  
  on(policyActions.fetchExcess, (state): PolicyState => ({
    ...state,
    excessEditState: ExcessEditState.Loading,
  })),

  on(policyActions.fetchExcessSuccess, (state, { excess }): PolicyState => ({
    ...state,
    excess: {
      ...excess,
      allowedExcesses: [ ...excess.allowedExcesses ].sort((a, b) => a - b),
    },
  })),

  on(policyActions.updateExcessSuccess, (state, { excess, monthlyTotal }): PolicyState => ({
    ...state,
    policy: {
      ...state.policy,
      excess,
      monthlyTotal,
      annualTotal: monthlyTotal * 12,
    },
    excess: {
      ...state.excess,
      excess,
      allowedExcesses: [],
    },
  })),

  on(policyActions.excessEditStateChanged, (state, { editState }): PolicyState => ({
    ...state,
    excessEditState: editState,
  })),

  on(policyActions.outstandingPaymentSuccess, (state): PolicyState => ({
    ...state,
    policy: {
      ...state.policy,
      outstandingPayment: 0,
      payments: { 
        ...state.policy.payments,
        annualCustomerCanMakePayment: false,
        monthlyCustomerCanMakeMissedPayment: false,
      },
    },
    canEditDirectDebitDetails: {
      ...state.canEditDirectDebitDetails,
      canEditDirectDebitDetails: state.policy.paymentFrequency === PolicyPaymentFrequency.Monthly,
    },
    paymentDayEditState: state.paymentDayEditState === PaymentDayEditState.PaymentConfirmation
      ? PaymentDayEditState.Editing : state.paymentDayEditState,
  })),

  on(policyActions.fetchStaticDocumentsSuccess, (state, { staticDocuments }): PolicyState => ({
    ...state,
    staticDocuments,
  })),

  on(policyActions.fetchExclusionsSuccess, (state, { exclusions }): PolicyState => ({
    ...state,
    exclusions,
  })),

  on(policyActions.fetchEditableFieldsSuccess, (state, { editableFields }): PolicyState => ({
    ...state,
    editableFields,
  })),

  on(policyActions.fetchClaimsSuccess, (state, { claims }): PolicyState => ({
    ...state,
    claims,
  })),

  on(policyActions.fetchCoverItemsSuccess, (state, { coverItems }): PolicyState => ({
    ...state,
    coverItems,
  })),

  on(policyActions.updatePetNameSuccess, (state, { petName }): PolicyState => ({
    ...state,
    policy:{
      ...state.policy,
      pet: {
        ...state.policy.pet,
        petName: petName,
      },
    },
  })),
  
  on(policyActions.updateMicrochipSuccess, (state, { microchipNo }): PolicyState => ({
    ...state,
    policy:{
      ...state.policy,
      pet: {
        ...state.policy.pet,
        microchipNo: microchipNo,
      },
    },
  })),
  
  on(policyActions.updatePassportSuccess, (state, { passportNo }): PolicyState => ({
    ...state,
    policy:{
      ...state.policy,
      pet: {
        ...state.policy.pet,
        passportNo: passportNo,
      },
    },
    petDetailsEditState: PetDetailsEditState.Success,
    canChangePassportNo: false,
  })),
  
  on(policyActions.updatePassportFailure, (state): PolicyState => ({
    ...state,
    petDetailsEditState: PetDetailsEditState.Failure,
  })),
  
  on(policyActions.updateGenderSuccess, (state, { gender }): PolicyState => ({
    ...state,
    policy:{
      ...state.policy,
      pet: {
        ...state.policy.pet,
        petGender: gender,
      },
    },
  })),
  
  on(policyActions.updateDOBSuccess, (state, { dob }): PolicyState => ({
    ...state,
    policy:{
      ...state.policy,
      pet: {
        ...state.policy?.pet,
        petDOB: dob,
      },
    },
  })),

  on(policyActions.updateBreedSuccess, (state, { breed }): PolicyState => ({
    ...state,
    policy:{
      ...state.policy,
      pet: {
        ...state.policy.pet,
        petBreed: breed,
      },
    },
  })),

  on(policyActions.fetchUploadedDocumentsSuccess, (state, { uploadedDocuments }): PolicyState => ({
    ...state,
    uploadedDocuments,
  })),

  on(policyActions.uploadedDocumentsPageChanged, (state, { page }): PolicyState => ({
    ...state,
    uploadedDocumentsPageParams: {
      ...state.uploadedDocumentsPageParams,
      skip: (page - 1) * state.uploadedDocumentsPageParams.top,
      page,
    },
  })),

  on(policyActions.updateHorseColourSuccess, (state, { colour }): PolicyState => ({
    ...state,
    policy: {
      ...state.policy,
      pet: {
        ...state.policy.pet,
        petColour: colour,
      },
    },
    petDetailsEditState: PetDetailsEditState.Success,
  })),

  on(policyActions.updateHorseColourFailure, (state): PolicyState => ({
    ...state,
    petDetailsEditState: PetDetailsEditState.Failure,
  })),
  
  on(policyActions.updateHorseColour, (state): PolicyState => ({
    ...state,
    petDetailsEditState: PetDetailsEditState.InProgress,
  })),

  on(policyActions.updatePaymentDaySuccess, (state, { paymentDay }): PolicyState => ({
    ...state,
    policy: {
      ...state.policy,
      preferredPaymentDay: paymentDay,
    },
    paymentDayEditState: PaymentDayEditState.Viewing,
    canChangePaymentDay: {
      ...state.canChangePaymentDay,
      canChangePaymentDay: false,
      hasExceedMaxNumberOfChanges: true,
    },
  })),

  on(policyActions.updatePaymentDayFailure, (state): PolicyState => ({
    ...state,
    paymentDayEditState: PaymentDayEditState.Failure,
  })),

  on(policyActions.updatePaymentDayEditState, (state, { editState }): PolicyState => ({
    ...state,
    paymentDayEditState: editState,
  })),

  on(policyActions.recalculateSchedulePaymentDay, (state, { paymentDay }): PolicyState => ({
    ...state,
    paymentDayEditState: PaymentDayEditState.InProgress,
    newPreferredPaymentDay: paymentDay,
  })),

  on(policyActions.recalculateSchedulePaymentDaySuccess,
    (state, { scheduleRecalculation }): PolicyState => ({
      ...state,
      paymentDayScheduleRecalculation: scheduleRecalculation,
      paymentDayEditState: PaymentDayEditState.Confirmation,
    }),
  ),

  on(policyActions.fetchCanChangePaymentDaySuccess,
    (state, { canChangePaymentDay }): PolicyState => ({
      ...state,
      canChangePaymentDay,
    }),
  ),
  
  on(policyActions.cancelPolicy, (state, props): PolicyState => ({
    ...state,
    cancellation: PetDetailsEditState.InProgress,
    cancellationDetails: {
      reason : props.reason,
      additionalComments: props.additionalComments,
      provider: props.provider,
    },
  })),
  
  on(policyActions.cancelPolicySuccess, (state): PolicyState => ({
    ...state,
    cancellation: PetDetailsEditState.Success,
  })),
  
  on(policyActions.cancelPolicyFailure, (state): PolicyState => ({
    ...state,
    cancellation: PetDetailsEditState.Failure,
  })),
  
  on(policyActions.cancelPolicyReset, (state): PolicyState => ({
    ...state,
    cancellation: null,
  })),

  on(RenewalActions.cancelPageLoaded, (state): PolicyState => ({
    ...state,
    cancellation: PetDetailsEditState.InProgress,
  })),

  on(RenewalActions.fetchDiscountSuccess, (state): PolicyState => ({
    ...state,
    cancellation: null,
  })),

  on(policyActions.setIsInRenewalWindow, (state, { isInRenewalWindow }): PolicyState => ({
    ...state,
    isInRenewalWindow,
  })),

  on(
    policyActions.fetchPreviousPolicySuccess,
    (state, props): PolicyState => ({
      ...state,
      previousPolicy: props.previousPolicy,
    }),
  ),

  on(
    policyActions.fetchPolicyDocumentsSuccess,
    (state, { policyDocuments }): PolicyState => ({
      ...state,
      policyDocuments,
    }),
  ),

  on(policyActions.fetchCanChangePassportNoSuccess, (
    state, { canChangePassportNo }): PolicyState => ({
    ...state,
    canChangePassportNo,
  })),
  
  on(
    policyActions.fetchHorseColoursSuccess,
    (state, { horseColours }): PolicyState => ({
      ...state,
      horseColours,
    }),
  ),

  on(policyActions.fetchHorseSuccess, (state, { horse }): PolicyState => ({
    ...state,
    policy: {
      ...state.policy,
      pet: {
        ...state.policy.pet,
        passportNo: horse.passportRef,
      },
    },
  })),

  on(
    policyActions.fetchPolicyFinanicalsSuccess,
    (state, { policyFinancials }): PolicyState => ({
      ...state,
      policyFinancials,
    }),
  ),
);
