import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FontAwesomeModule } from '@fortawesome/angular-fontawesome';
import { RouterModule } from '@angular/router';

import { HeaderComponent } from './header.component';
import { FullHeaderComponent } from './full-header/full-header.component';
import { MobileHeaderComponent } from './mobile-header/mobile-header.component';
import { TranslateModule } from '@ngx-translate/core';
import { NavbarContainerModule } from '@core/layout/containers/navbar-container/navbar-container.module';

@NgModule({
  imports: [
    CommonModule,
    FontAwesomeModule,
    TranslateModule,
    RouterModule,
    NavbarContainerModule,
  ],
  declarations: [HeaderComponent, FullHeaderComponent, MobileHeaderComponent],
  exports: [HeaderComponent, FullHeaderComponent, MobileHeaderComponent],
})
export class HeaderModule {}
