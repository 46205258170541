import { HttpClient } from "@angular/common/http";
import { Inject, Injectable } from "@angular/core";
import { BuildSettings, BUILD_SETTINGS } from "@core/configuration/tokens/build-settings.token";
import { RetrieveAddressResult } from "@features/account-details/components/Interfaces/address-retrieve-result.interface";
import { AddressSearchResponse } from "@shared/interfaces/address-search-response.interface";
import { Observable} from "rxjs";

@Injectable({
    providedIn: 'root',
  })  
export class AddressLookupService {
  private PDVBaseURL = `${this.buildSettings.API}/PersonalDataVerification`;
    constructor(
      private http: HttpClient,
      @Inject(BUILD_SETTINGS) private buildSettings: BuildSettings,
    ) {}

    search(text: string, container?: string): Observable<AddressSearchResponse[]> {
      const params = { text, ...(container && { container }) };
      return this.http.get<AddressSearchResponse[]>(`${this.PDVBaseURL}/search`, { params });
    }

    retrieveAddress(addressId: string): Observable<RetrieveAddressResult[]> {
      return this.http.get<RetrieveAddressResult[]>(
        `${this.PDVBaseURL}/RetrieveAddress?Id=${addressId}`,
      );
    }
    
    validatePostcode(postcode: string): Observable<boolean> {
      return this.http.get<boolean>(
        `${this.PDVBaseURL}/ValidatePostCode/${encodeURIComponent(postcode)}`,
      );
    }

}
