import { NgModule } from "@angular/core";
import { StoreModule } from "@ngrx/store";
import { symptomCheckerStateName } from "./symptom-checker-state";
import { symptomCheckerReducer } from "./reducers/symptom-checker.reducer";
import { EffectsModule } from "@ngrx/effects";
import { SymptomCheckerEffects } from "./effects/symptom-checker.effects";


@NgModule({
  imports: [
    EffectsModule.forFeature([
      SymptomCheckerEffects,
    ]),
    StoreModule.forFeature(symptomCheckerStateName, symptomCheckerReducer),
  ],
})
export class SymptomCheckerStoreModule{}