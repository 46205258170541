import { createAction, props } from "@ngrx/store";

const setBreeding = createAction(
    '[User Consent] Set pet not used for breeding',
    props<{breeding: boolean}>(),
);
const setUkResident = createAction(
    '[User Consent] Set UK resident',
    props<{ukResident: boolean}>(),
);
const setExcluded = createAction(
    '[User Consent] Set pet not excluded',
    props<{excluded: boolean}>(),
);
const setConfirmation = createAction(
    '[User Consent] Set confirm terms and conditions',
    props<{confirmation: boolean}>(),
);
const setChanges = createAction(
    '[User Consent] Set will inform AFI of changes',
    props<{changes: boolean}>(),
);

export const userConsentActions = {
    setBreeding,
    setUkResident,
    setExcluded,
    setConfirmation,
    setChanges,
}