import { Inject, Injectable } from '@angular/core';
import {
  BuildSettings,
  BUILD_SETTINGS,
} from '@core/configuration/tokens/build-settings.token';
import { EnvironmentService } from '@core/environment/services/environment.service';
import { ApplicationInsights } from '@microsoft/applicationinsights-web';

@Injectable({
  providedIn: 'root',
})
export class MonitoringService {
  readonly appInsights: ApplicationInsights;
  readonly production = this.environmentService.production;

  constructor(
    @Inject(BUILD_SETTINGS) private buildSettings: BuildSettings,
    private environmentService: EnvironmentService,
  ) {
    this.appInsights = new ApplicationInsights({
      config: {
        instrumentationKey: this.buildSettings.AppInsightsInstrumentationKey,
        enableAutoRouteTracking: this.production,
        disableExceptionTracking: !this.production,
      },
    });
    this.appInsights.loadAppInsights();
  }

  /*
   * The methods below are for manual logging.
   */
  logPageView(name?: string, uri?: string): void {
    if (this.production) {
      this.appInsights.trackPageView({ name, uri });
    }
  }

  logEvent(name: string, properties?: { [key: string]: any }): void {
    if (this.production) {
      this.appInsights.trackEvent({ name }, properties);
    }
  }

  logMetric(
    name: string,
    average: number,
    properties?: { [key: string]: any },
  ): void {
    if (this.production) {
      this.appInsights.trackMetric({ name, average }, properties);
    }
  }

  logException(exception: Error, severityLevel?: number): void {
    console.error(exception);
    if (this.production) {
      this.appInsights.trackException({ exception, severityLevel });
    }
  }

  logTrace(message: string, properties?: { [key: string]: any }): void {
    if (this.production) {
      this.appInsights.trackTrace({ message }, properties);
    }
  }
}
