import { createSelector } from "@ngrx/store";
import { selectChangeCoverState } from "./change-cover.selector";
import { PolicySelectors } from "@features/policies/store/policy.selectors";
import { Policy } from "@interfaces/policy";
import { ChangeCoverQuote } from "@features/change-cover/interfaces/change-cover-quote";


const selectCoverSelectionState = createSelector(
  selectChangeCoverState,
  (state) => state.coverSelection,
);

const selectCoverType = createSelector(
  selectCoverSelectionState,
  (state) => state.coverType,
);

const selectProductType = createSelector(
  selectCoverSelectionState,
  (state) => state.productType,
);

const selectLimitType = createSelector(
  selectCoverSelectionState,
  (state) => state.limitType,
);

const selectCoverProduct = createSelector(
  selectCoverSelectionState,
  (state) => state.coverProduct,
);

const selectExcess = createSelector(
  selectCoverSelectionState,
  (state) => state.excess,
);

const selectChangeCoverQuote = createSelector(
  selectCoverSelectionState,
  (state) => state.changeCoverQuote,
);

const selectCurrentCover = createSelector(
  selectCoverSelectionState,
  (state) => state.currentCover,
);

const selectPossibleCovers = createSelector(
  selectCoverSelectionState,
  (state) => state.possibleCovers,
);

const selectCoverChangeType = createSelector(
  selectCoverSelectionState,
  (state) => state.coverChangeType,
);

const selectPolicyQuote = createSelector(
  PolicySelectors.selectPolicy,
  selectPossibleCovers,
  (policy: Policy, quotes: ChangeCoverQuote[]) => {
    if (policy === null) {
      return undefined;
    }

    const currentProductQuotes = quotes.filter(x => x.productId === policy.productId);
    return currentProductQuotes.find(x => x.excess === policy.excess);
  },
);

const selectHasExcessChanged = createSelector(
  PolicySelectors.selectPolicy,
  selectChangeCoverQuote,
  (policy: Policy, quote: ChangeCoverQuote) => policy.excess !== quote.excess,
);

const selectTreatmentData = createSelector(
  selectCoverSelectionState,
  (state) => state.treatmentData,
);

const selectDogTreatmentData = createSelector(
  selectTreatmentData,
  (state) => state?.dog,
);

const selectCatTreatmentData = createSelector(
  selectTreatmentData,
  (state) => state?.cat,
);

export const CoverSelectionSelectors = {
  selectCoverSelectionState,
  selectCoverType,
  selectProductType,
  selectLimitType,
  selectCoverProduct,
  selectExcess,
  selectChangeCoverQuote,
  selectCurrentCover,
  selectPossibleCovers,
  selectCoverChangeType,
  selectPolicyQuote,
  selectHasExcessChanged,
  selectDogTreatmentData,
  selectCatTreatmentData,
};