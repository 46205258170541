import { Injectable } from '@angular/core';
import { Event, NavigationError, Router } from '@angular/router';
import { ErrorsService } from '@core/error-handling/services/errors.service';
import { createEffect } from '@ngrx/effects';
import { filter, map, tap } from 'rxjs/operators';

@Injectable()
export class MonitoringEffects {
  constructor(private router: Router, private errorsService: ErrorsService) {}

  public navigationError$ = createEffect(
    () => {
      return this.router.events.pipe(
        map((event: Event) => event instanceof NavigationError ? event : undefined),
        filter((event) => !!event),
        filter(() => navigator.onLine),
        tap((event) => this.errorsService.handleError(event.error)),
      ) },
    { dispatch: false },
  );
}
