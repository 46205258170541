import { AlgorithmCategory } from "@features/joii/interfaces/algorithm-category";
import { GetNextNodeRequest } from "@features/joii/interfaces/get-next-node-request";
import { GetNextNodeResponse } from "@features/joii/interfaces/get-next-node-response";
import { GetPreviousNodeResponse } from "@features/joii/interfaces/get-previous-node-response";
import { StartSessionResponse } from "@features/joii/interfaces/start-session-response";
import { SymptomCheckerNode } from "@features/joii/interfaces/symptom-checker-node";
import { createAction, props } from "@ngrx/store";

const setPetWeight = createAction(
  '[Symptom Checker] Set Pet Weight',
  props<{petWeight: number}>(),
);

const setIsNeutered = createAction(
  '[Symptom Checker] Set Is Neutered',
  props<{isNeutered: boolean}>(),
);

const setAlgorithmCategoryDocId = createAction(
  '[Symptom Checker] Set Algorithm Category',
  props<{algorithmCategoryDocId: string}>(),
);

const fetchAlgorithmCategoryOptions = createAction(
  '[Symptom Checker] Fetch Algorithm Category Options',
);

const setAlgorithmCategoryOptions = createAction(
  '[Symptom Checker] Set Algorithm Category Options',
  props<{algorithmCategoryOptions: AlgorithmCategory[]}>(),
);

const setCurrentNode = createAction(
  '[Symptom Checker] Set Current Node',
  props<{currentNode: SymptomCheckerNode}>(),
);

const requestStartSession = createAction(
  '[Symptom Checker] Request Start Session',
);

const requestStartSessionSuccess = createAction(
  '[Symptom Checker] Request Start Session Success',
  props<{startSessionResponse: StartSessionResponse}>(),
);

const fetchNextNode = createAction(
  '[Symptom Checker] Fetch Next Node',
  props<{getNextNodeRequest: GetNextNodeRequest}>(),
);

const fetchNextNodeSuccess = createAction(
  '[Symptom Checker] Fetch Next Node Success',
  props<{response: GetNextNodeResponse}>(),
);

const fetchPreviousNode = createAction(
  '[Symptom Checker] Fetch Previous Node',
);

const fetchPreviousNodeSuccess = createAction(
  '[Symptom Checker] Fetch Previous Node Success',
  props<{response: GetPreviousNodeResponse}>(),
);

export const symptomCheckerActions = {
  setPetWeight,
  setIsNeutered,
  setAlgorithmCategoryDocId,
  setAlgorithmCategoryOptions,
  setCurrentNode,
  fetchAlgorithmCategoryOptions,
  requestStartSession,
  requestStartSessionSuccess,
  fetchNextNode,
  fetchNextNodeSuccess,
  fetchPreviousNode,
  fetchPreviousNodeSuccess,
};
