import { Injectable } from '@angular/core';
import { OAuthService } from 'angular-oauth2-oidc';
import { ADB2CSession } from '../models/adb2c-session';
import { Adb2cService } from './adb2c.service';

@Injectable({
  providedIn: 'root',
})
export class AuthenticationService {
  constructor(
    private oauthService: OAuthService,
    private adb2cService: Adb2cService,
  ) {}

  getAccount(): ADB2CSession {
    return this.oauthService.getIdentityClaims() as ADB2CSession;
  }

  resetPassword(): void {
    this.adb2cService.navigateToResetPassword();
  }

  changePassword(): void {
    this.adb2cService.navigateToChangePassword();
  }

  logout(): void {
    this.oauthService.logOut();
  }
}
