<div class="p-4">
  <a class="action-link ms-auto" (click)="activeModal.close('Close click')" ngbAutofocus translate>AUTH.POLICY.CHANGEOFCOVER.COPAYMENTSEXPLANATION.CLOSE</a>
  <h4 class="heading mt-3 mb-3" translate>AUTH.POLICY.CHANGEOFCOVER.COPAYMENTSEXPLANATION.HEADING</h4>
  <app-copayments-explanation [policy]="policy">
  </app-copayments-explanation>
  <div class="mt-3"
    data-e2e="copayments-explanation-modal-example-text"
    [innerHTML]="'AUTH.POLICY.CHANGEOFCOVER.COPAYMENTSEXPLANATION.EXAMPLE.' + 
    productVersion + '.' + 
    (productType | removeWhitespace | uppercase) + 
    ((limitType ? '.' + limitType : '') | removeWhitespace | uppercase) | translate: 
    { amountRemaining: (amountRemaining | currency : 'GBP' : 'symbol' : amountRemaining % 1 > 0 ? '1.2-2' : '1.0-0'),
    limit: (limit | currency : 'GBP' : 'symbol' : '1.0-0') }">
  </div>
  <div class="justify-content-between mt-3">
    <button type="button" class="btn btn-primary select-btn rounded-3 w-100" (click)="activeModal.close('Close click')" translate>
      AUTH.POLICY.CHANGEOFCOVER.COPAYMENTSEXPLANATION.CLOSE
    </button>
  </div>
</div>