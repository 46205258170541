import { HttpErrorResponse } from '@angular/common/http';
import { Inject, Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { environment } from 'src/environments/environment';
import { SilentError } from '../models/silent.error';
import { MonitoringService } from '@core/monitoring/services/monitoring.service';
import {
  BuildSettings,
  BUILD_SETTINGS,
} from '@core/configuration/tokens/build-settings.token';

export const APIErrorNonFieldKey = '';

@Injectable({
  providedIn: 'root',
})
export class ErrorsService {
  static ErrorUrl = '/error';

  constructor(
    private monitoringService: MonitoringService,
    private router: Router,
    @Inject(BUILD_SETTINGS) private buildSettings: BuildSettings,
  ) {}

  parseAPIError(error: APIError | string): string[] {
    return (
      (typeof error === 'object' && error.errors[APIErrorNonFieldKey]) ||
      (typeof error === 'string' && [error]) ||
      undefined
    );
  }

  handleError(error: Error) {
    // Silent errors.
    if (error instanceof SilentError) {
      return;
    }

    this.log(error);

    // Log but don't navigate to error page for HTTP errors.
    if (error instanceof HttpErrorResponse) {
      return;
    }

    if (!environment.production) {
      // eslint-disable-next-line no-debugger
      // debugger;
    }

    if (error instanceof HttpErrorResponse) {
      if (error.url.indexOf(this.buildSettings.API) === -1) {
        return;
      }
    }

    // if it came from an external source e.g. the chatbot, then ignore.
    if (this.fromExternal(error)) {
      return;
    }

    this.router.navigate([ErrorsService.ErrorUrl]);
  }
  
  fromExternal(error: Error): boolean {
    let result = false;
    if (!error.stack)
    {
      return result;
    }
    const errorOrigin = error.stack.split('\n').find(line => line.trim().startsWith('at'));
    if (errorOrigin)
    {
      result = (errorOrigin.indexOf(window.location.hostname) === -1);
    }
    return result;
  }


  log(error: Error): void {
    this.monitoringService.logException(error);
  }
}

export interface ErrorContext {
  status: number;
  message: string;
  stack: string;
}

export interface APIError {
  [field: string]: {
    [field: string]: string[];
  };
}
