export enum LoggedInRouterPaths {
  Policies = 'policies',
  Account = 'account',
  Payments = 'payments',
  Claims = 'claims',
  Joii = 'joii',
  AwaitingPolicies = 'awaiting-policies',
  SupportingDocuments = 'supporting-documents',
  ChangeCover = 'change-cover',
  Offers = 'offers',
  ReferralDashboard = 'refer-a-friend',
  Renewal = 'renewal',
}
