import { HttpClient, HttpHeaders, HttpEvent } from '@angular/common/http';
import { Inject, Injectable } from '@angular/core';
import {
  BUILD_SETTINGS,
  BuildSettings,
} from '@core/configuration/tokens/build-settings.token';
import { Claim } from '@interfaces/claim';
import { PaginatedQuery } from '@interfaces/paginated-query';
import { PaginatedResults } from '@interfaces/paginated-results';
import { Observable } from 'rxjs';
import { ClaimCondition } from '../interfaces/claim-condition.interface';

@Injectable({
  providedIn: 'root',
})
export class ClaimService {
  readonly masterDataAPIBaseURL = `${this.buildSettings.MASTERDATA_API}/v1`;

  static readonly DefaultPaginatedQuery: PaginatedQuery = {
    top: 10,
    skip: 0,
  };

  constructor(
    @Inject(BUILD_SETTINGS) private buildSettings: BuildSettings,
    private http: HttpClient,
  ) {}

  
  /**
   * Get all claims.
   */
  getClaims(query?: GetClaimsQuery): Observable<PaginatedResults<Claim>> {
    return this.http.get<PaginatedResults<Claim>>(
      `${this.buildSettings.API}/SubClaims`,
      {
        params: {
          ...(query as any),
        },
      },
    );
  }

  /**
   * Get a specific claim.
   *
   * @param claimSubID Claim ID.
   */
  getClaim(claimSubID: number): Observable<Claim> {
    return this.http.get<Claim>(
      `${this.buildSettings.API}/SubClaims/${claimSubID}`,
    );
  }

  /**
   * Create a new claim.
   *
   * @param formData Form data to send to server.
   */
  createClaim(formData: FormData, isV2Claim): Observable<HttpEvent<any>> {
    
    return this.http.post<HttpEvent<any>>(
      isV2Claim?
      `${this.buildSettings.API}/v2.0/Claims`: `${this.buildSettings.API}/Claims`,
      formData,
      {
        reportProgress: true,
        observe: 'events',
        headers: new HttpHeaders({
          'Content-Disposition': 'multipart/form-data',
        }),
      },
    );
  }

  getClaimConditions = (riskType = '') => this.http.get<ClaimCondition[]>(`${this.masterDataAPIBaseURL}/Conditions?riskType=${riskType}`);
}

export interface GetClaimsQuery extends PaginatedQuery {
  pet?: string;
  policyUniqueRef?: string;
}
