import { Component, Input } from '@angular/core';
import { PolicyMethods } from '@features/policies/services/policy-methods.service';
import { Policy } from '@interfaces/policy';

@Component({
  selector: 'app-copayments-explanation',
  templateUrl: './copayments-explanation.component.html',
  styleUrls: ['./copayments-explanation.component.scss'],
})
export class CopaymentsExplanationComponent {

  @Input()
  public readonly policy: Policy;

  constructor(
    private policyMethods: PolicyMethods,
  ) {}

  getcoPaymentAge(): number {
    return this.policyMethods.getCopaymentAge(this.policy);
  }

  getPetAge(): number{
    return this.policyMethods.getPetAge(new Date(this.policy.pet.petDOB), new Date());
  }
}
