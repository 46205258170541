import { ClientSettings } from '@core/configuration/tokens/client-settings.token';

export const MockClientSettings: ClientSettings = {
  oAuthIdentity: {
    scope: ['https://afib2cdev.onmicrosoft.com/chubdevapi/user_impersonation'],
    authority: 'AFIB2CDEV',
    audience: 'AFIB2CDEV',
    instance: 'mockInstance/',
    domain: 'mockDomain', 
    signUpSignInPolicyId: 'b2c_1a_signup_signin',
    editProfilePolicyId: 'b2c_1a_ProfileEdit_PasswordChange',
    resetPasswordPolicyId: 'b2c_1a_PasswordReset',
    clientId: '4c04ab06-d633-48f4-955e-d3a1c9d621f9',
  },
  endpoints: {
    campaign: 'http://www.animal-friends.co.uk/',
  },
  publicWebsite: {
    baseUrl: 'https://animalfriends.co.uk',
    contactUsUrl: 'https://animalfriends.co.uk',
    cookiePolicyUrl: 'https://animalfriends.co.uk',
    privacyPolicyUrl: 'https://animalfriends.co.uk',
    termsandConditionsUrl: 'https://animalfriends.co.uk',
    termsofBusinessUrl: 'https://animalfriends.co.uk',
  },
};
