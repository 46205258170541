import { ModuleWithProviders, NgModule } from '@angular/core';
import { oauthErrorInterceptorProvider } from './interceptors/oauth-error-interceptor';
import { oauthInterceptorProvider } from './interceptors/oauth-interceptor';
import { oauthInitializerProvider } from './providers/oauth-initializer.provider';

@NgModule()
export class AuthenticationModule {
  static forRoot(): ModuleWithProviders<AuthenticationModule> {
    return {
      ngModule: AuthenticationModule,
      providers: [
        oauthInterceptorProvider,
        oauthInitializerProvider,
        oauthErrorInterceptorProvider,
      ],
    };
  }
}
