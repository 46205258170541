import { createFeatureSelector, createSelector } from "@ngrx/store";
import { FeatureFlagState } from "../interfaces/feature-flag-state.interface";
import { featureFlagStateName } from "./feature-flag.state";

export const selectFeatureFlagState = createFeatureSelector<FeatureFlagState>(featureFlagStateName);

export const selectIsFeatureEnabled = (feature: string) =>
  createSelector(
    selectFeatureFlagState,
    (featureFlags) => featureFlags.featureFlagSettings
	  .find(setting => setting.feature === feature)?.isEnabled ?? false,
  );

export const selectInitialised = createSelector(
  selectFeatureFlagState,
  (state) => state.initialised,
);

