import {
  CUSTOM_ELEMENTS_SCHEMA,
  NgModule,
  Optional,
  SkipSelf,
} from '@angular/core';
import { RouterModule } from '@angular/router';
import { CommonModule } from '@angular/common';
import { TranslateModule } from '@ngx-translate/core';
import { AnalyticsModule } from './analytics/analytics.module';
import { EnvironmentModule } from './environment/environment.module';
import { CoreComponent } from './core.component';
import { AuthenticationModule } from './authentication/authentication.module';
import { ErrorHandlingModule } from './error-handling/error-handling.module';
import { ConfigurationModule } from './configuration/configuration.module';
import { MonitoringModule } from './monitoring/monitoring.module';

@NgModule({
  imports: [
    CommonModule,
    TranslateModule,
    EnvironmentModule,
    RouterModule,
    AnalyticsModule,
    AuthenticationModule.forRoot(),
    ErrorHandlingModule.forRoot(),
    ConfigurationModule,
    MonitoringModule,
  ],
  declarations: [CoreComponent],
  exports: [CoreComponent],
  schemas: [CUSTOM_ELEMENTS_SCHEMA],
})
export class CoreModule {
  constructor(@Optional() @SkipSelf() parentModule: CoreModule) {
    if (parentModule) {
      throw new Error(
        'CoreModule has already been loaded. You should only import Core modules in the AppModule only.',
      );
    }
  }
}
