import {
  HttpEvent,
  HttpHandler,
  HttpInterceptor,
  HttpRequest,
  HttpResponse,
  HTTP_INTERCEPTORS,
} from '@angular/common/http';
import { Injectable, Provider } from '@angular/core';
import { OAuthService } from 'angular-oauth2-oidc';
import { from, Observable, throwError } from 'rxjs';
import { catchError, filter, finalize, switchMap, take } from 'rxjs/operators';
import { AuthenticationUtilsService } from '../services/authentication-utils.service';

@Injectable({ providedIn: 'root' })
export class OauthErrorInterceptor implements HttpInterceptor {
  private refreshTokenInProgress = false;

  constructor(
    private oauthService: OAuthService,
    private authenticationUtils: AuthenticationUtilsService,
  ) {}

  intercept(
    req: HttpRequest<any>,
    next: HttpHandler,
  ): Observable<HttpEvent<any>> {
    if (!this.authenticationUtils.isAPI(req.url)) {
      return next.handle(req);
    }

    return next.handle(req).pipe(
      catchError((response: unknown) => {
        if (response && response instanceof HttpResponse && response.status === 401) {
          if (this.refreshTokenInProgress) {
            // If refreshTokenInProgress is true, we will wait until token is silently refreshed
            // which means the new token is ready and we can retry the request
            return this.oauthService.events.pipe(
              filter((result) => result.type === 'silently_refreshed'),
              take(1),
              switchMap(() =>
                next.handle(this.authenticationUtils.getAuthRequest(req)),
              ),
            );
          } else {
            this.refreshTokenInProgress = true;

            return from(this.oauthService.refreshToken()).pipe(
              switchMap(() => next.handle(
                  this.authenticationUtils.getAuthRequest(req),
                )),
              catchError((error: unknown) => {
                this.oauthService.initCodeFlow();
                return throwError(error);
              }),
              // When the call to silentRefresh completes we reset the refreshTokenInProgress to false
              // for the next time the token needs to be refreshed
              finalize(() => (this.refreshTokenInProgress = false)),
            );
          }
        }

        return throwError(response);
      }),
    );
  }
}

export const oauthErrorInterceptorProvider: Provider = {
  provide: HTTP_INTERCEPTORS,
  useClass: OauthErrorInterceptor,
  multi: true,
};
