import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { FeatureContainerComponent } from './feature-container.component';

@NgModule({
  declarations: [FeatureContainerComponent],
  imports: [CommonModule],
  exports: [FeatureContainerComponent],
})
export class FeatureContainerModule {}
