import { createReducer, on } from '@ngrx/store';
import {
  CommunicationPreferencesState,
  initialCommunicationPreferencesState,
} from '../state';
import { communicationPreferencesActions } from '../actions/communication-preferences.actions';
import { ContactFormat } from '@features/communication-preferences/enums/contact-format.enum';
import { DocumentContactPreference } from '@features/communication-preferences/enums/document-contact.enum';
import { coreActions } from '@core/store/actions/core.actions';

export const communicationPreferencesReducer = createReducer(
  initialCommunicationPreferencesState,

  on(
    communicationPreferencesActions.fetchCommunicationPreferences,
    (state): CommunicationPreferencesState => ({
      ...state,
      loading: true,
    }),
  ),

  on(
    communicationPreferencesActions.fetchCommunicationPreferencesSuccess,
    (state, props): CommunicationPreferencesState => ({
      ...state,
      communicationPreferences: props.communicationPreferences,
      initialCommunicationPreferences: props.communicationPreferences,
      loading: false,
    }),
  ),

  on(
    communicationPreferencesActions.fetchCommunicationPreferencesFailure,
    (state): CommunicationPreferencesState => ({
      ...state,
      loading: false,
    }),
  ),

  on(
    communicationPreferencesActions.documentContactPreferenceChanged,
    (state, { documentContactPreference }): CommunicationPreferencesState => ({
      ...state,
      communicationPreferences: {
        ...state.communicationPreferences,
        documentsEmail:
          documentContactPreference === DocumentContactPreference.Email,
        documentsPost:
          documentContactPreference === DocumentContactPreference.Post,
        joinedConsumerPanel:
          documentContactPreference === DocumentContactPreference.Post
            ? false
            : state.communicationPreferences.joinedConsumerPanel,
      },
    }),
  ),

  on(
    communicationPreferencesActions.marketingOptionsChanged,
    (state, props): CommunicationPreferencesState => ({
      ...state,
      communicationPreferences: {
        ...state.communicationPreferences,
        marketingEmail: props.marketingPreferences.marketingEmail,
        marketingPhone: props.marketingPreferences.marketingPhone,
        marketingPost: props.marketingPreferences.marketingPost,
        marketingSms: props.marketingPreferences.marketingSms,
      },
    }),
  ),

  on(
    communicationPreferencesActions.contactFormatChanged,
    (state, { contactFormat }): CommunicationPreferencesState => {
      const forcePost =
        contactFormat === ContactFormat.AudioFile ||
        contactFormat === ContactFormat.Braille;
      return {
        ...state,
        communicationPreferences: {
          ...state.communicationPreferences,
          contactFormat,
          documentsEmail:
            state.communicationPreferences.documentsEmail && !forcePost,
          documentsPost:
            state.communicationPreferences.documentsPost || forcePost,
        },
      };
    },
  ),

  on(
    communicationPreferencesActions.consumerPanelOptIn,
    (state): CommunicationPreferencesState => ({
      ...state,
      communicationPreferences: {
        ...state.communicationPreferences,
        joinedConsumerPanel: true,
      },
    }),
  ),

  on(
    communicationPreferencesActions.consumerPanelOptOut,
    (state): CommunicationPreferencesState => ({
      ...state,
      communicationPreferences: {
        ...state.communicationPreferences,
        joinedConsumerPanel: false,
      },
    }),
  ),

  on(
    communicationPreferencesActions.documentContactPreferenceChanged,
    communicationPreferencesActions.marketingOptionsChanged,
    communicationPreferencesActions.contactFormatChanged,
    communicationPreferencesActions.consumerPanelOptIn,
    communicationPreferencesActions.consumerPanelOptOut,
    (state): CommunicationPreferencesState => ({
      ...state,
      communicationPreferencesChanged: true,
      error: null,
      success: false,
    }),
  ),

  on(
    communicationPreferencesActions.updateCommunicationPreferences,
    (state): CommunicationPreferencesState => ({
      ...state,
      success: false,
      error: null,
      saving: true,
    }),
  ),

  on(
    communicationPreferencesActions.updateCommunicationPreferencesSuccess,
    (state): CommunicationPreferencesState => ({
      ...state,
      initialCommunicationPreferences: { ...state.communicationPreferences },
      success: true,
      communicationPreferencesChanged: false,
      saving: false,
    }),
  ),

  on(
    communicationPreferencesActions.updateCommunicationPreferencesError,
    (state, props): CommunicationPreferencesState => ({
      ...state,
      error: props.error,
      saving: false,
    }),
  ),

  on(
    communicationPreferencesActions.cancelChanges,
    (state): CommunicationPreferencesState => ({
      ...state,
      communicationPreferencesChanged: false,
      communicationPreferences: { ...state.initialCommunicationPreferences },
      error: null,
      success: false,
    }),
  ),

  on(coreActions.resetStore, 
    (): CommunicationPreferencesState => ({
      ...initialCommunicationPreferencesState,
    })),
);
