<p data-e2e="vet-fees-example-v2-claim-summary" translate>AUTH.POLICY.CHANGEOFCOVER.REVIEW.LOWCOVERPRODUCT.EXAMPLE.MODAL.CLAIMEXAMPLESUMMARY
</p>
<div class="row">
  <div class="col-7 col-lg-5" translate>AUTH.POLICY.CHANGEOFCOVER.REVIEW.LOWCOVERPRODUCT.EXAMPLE.MODAL.NOCOPAYMENT.AMOUNT</div>
  <div class="col-5 col-lg-7" translate>AUTH.POLICY.CHANGEOFCOVER.REVIEW.LOWCOVERPRODUCT.EXAMPLE.MODAL.NOCOPAYMENT.AMOUNTVALUE</div>
</div>
<div class="row mb-2 d-flex">
  <div class="col-7 col-lg-5 fw-bold" translate>AUTH.POLICY.CHANGEOFCOVER.REVIEW.LOWCOVERPRODUCT.EXAMPLE.MODAL.NOCOPAYMENT.ANIMALFRIENDS</div>
  <div class="col-5 col-lg-7 align-self-end" translate>AUTH.POLICY.CHANGEOFCOVER.REVIEW.LOWCOVERPRODUCT.EXAMPLE.MODAL.NOCOPAYMENT.ANIMALFRIENDSVALUE</div>
</div>
<div class="row">
  <div class="col fw-bold" translate>AUTH.POLICY.CHANGEOFCOVER.REVIEW.LOWCOVERPRODUCT.EXAMPLE.MODAL.NOCOPAYMENT.PAYTOVET</div>
</div>
<div class="row">
  <div class="col-7 col-lg-5" translate>AUTH.POLICY.CHANGEOFCOVER.REVIEW.LOWCOVERPRODUCT.EXAMPLE.MODAL.NOCOPAYMENT.EXCESS</div>
  <div class="col-5 col-lg-7" translate>AUTH.POLICY.CHANGEOFCOVER.REVIEW.LOWCOVERPRODUCT.EXAMPLE.MODAL.NOCOPAYMENT.EXCESSVALUE</div>
</div>
<div class="row mb-3">
  <div class="col-7 col-lg-5" translate>AUTH.POLICY.CHANGEOFCOVER.REVIEW.LOWCOVERPRODUCT.EXAMPLE.MODAL.NOCOPAYMENT.REMAINDER</div>
  <div class="col-5 col-lg-7" translate>AUTH.POLICY.CHANGEOFCOVER.REVIEW.LOWCOVERPRODUCT.EXAMPLE.MODAL.NOCOPAYMENT.REMAINDERVALUE</div>
</div>
<div class="row mb-4">
  <div class="col-7 col-lg-5 fw-bold" translate>AUTH.POLICY.CHANGEOFCOVER.REVIEW.LOWCOVERPRODUCT.EXAMPLE.MODAL.NOCOPAYMENT.YOURPAYMENT</div>
  <div class="col-5 col-lg-7" translate>AUTH.POLICY.CHANGEOFCOVER.REVIEW.LOWCOVERPRODUCT.EXAMPLE.MODAL.NOCOPAYMENT.YOURPAYMENTVALUE</div>
</div>
<p class="fw-bold mb-4" translate>
  AUTH.POLICY.CHANGEOFCOVER.REVIEW.LOWCOVERPRODUCT.EXAMPLE.MODAL.COPAYMENT.SUMMARY
</p>
<div class="row">
  <div class="col-7 col-lg-5" translate>AUTH.POLICY.CHANGEOFCOVER.REVIEW.LOWCOVERPRODUCT.EXAMPLE.MODAL.COPAYMENT.AMOUNT</div>
  <div class="col-5 col-lg-7" translate>AUTH.POLICY.CHANGEOFCOVER.REVIEW.LOWCOVERPRODUCT.EXAMPLE.MODAL.COPAYMENT.AMOUNTVALUE</div>
</div>
<div class="row mb-2 d-flex">
  <div class="col-7 col-lg-5 fw-bold" translate>AUTH.POLICY.CHANGEOFCOVER.REVIEW.LOWCOVERPRODUCT.EXAMPLE.MODAL.COPAYMENT.ANIMALFRIENDS</div>
  <div class="col-5 col-lg-7 align-self-end" translate>AUTH.POLICY.CHANGEOFCOVER.REVIEW.LOWCOVERPRODUCT.EXAMPLE.MODAL.COPAYMENT.ANIMALFRIENDSVALUE</div>
</div>
<div class="row">
  <div class="col fw-bold" translate>AUTH.POLICY.CHANGEOFCOVER.REVIEW.LOWCOVERPRODUCT.EXAMPLE.MODAL.COPAYMENT.PAYTOVET</div>
</div>
<div class="row">
  <div class="col-7 col-lg-5" translate>AUTH.POLICY.CHANGEOFCOVER.REVIEW.LOWCOVERPRODUCT.EXAMPLE.MODAL.COPAYMENT.EXCESS</div>
  <div class="col-5 col-lg-7" translate>AUTH.POLICY.CHANGEOFCOVER.REVIEW.LOWCOVERPRODUCT.EXAMPLE.MODAL.COPAYMENT.EXCESSVALUE</div>
</div>
<div class="row">
  <div class="col-7 col-lg-5" translate>AUTH.POLICY.CHANGEOFCOVER.REVIEW.LOWCOVERPRODUCT.EXAMPLE.MODAL.COPAYMENT.COPAYMENT</div>
  <div class="col-5 col-lg-7" translate>AUTH.POLICY.CHANGEOFCOVER.REVIEW.LOWCOVERPRODUCT.EXAMPLE.MODAL.COPAYMENT.COPAYMENTVALUE</div>
</div>
<div class="row mb-3">
  <div class="col-7 col-lg-5" translate>AUTH.POLICY.CHANGEOFCOVER.REVIEW.LOWCOVERPRODUCT.EXAMPLE.MODAL.COPAYMENT.REMAINDER</div>
  <div class="col-5 col-lg-7" translate>AUTH.POLICY.CHANGEOFCOVER.REVIEW.LOWCOVERPRODUCT.EXAMPLE.MODAL.COPAYMENT.REMAINDERVALUE</div>
</div>
<div class="row mb-4">
  <div class="col-7 col-lg-5 fw-bold" translate>AUTH.POLICY.CHANGEOFCOVER.REVIEW.LOWCOVERPRODUCT.EXAMPLE.MODAL.COPAYMENT.YOURPAYMENT</div>
  <div class="col-5 col-lg-7" translate>AUTH.POLICY.CHANGEOFCOVER.REVIEW.LOWCOVERPRODUCT.EXAMPLE.MODAL.COPAYMENT.YOURPAYMENTVALUE</div>
</div>
<p [innerHTML]="'AUTH.POLICY.CHANGEOFCOVER.REVIEW.LOWCOVERPRODUCT.EXAMPLE.MODAL.CONDITIONLIMITUSED' | translate">

</p>