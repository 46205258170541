import { createFeatureSelector, createSelector } from "@ngrx/store";
import { SymptomCheckerState, symptomCheckerStateName } from "../symptom-checker-state";


const selectSymptomCheckerState = createFeatureSelector<SymptomCheckerState>(
  symptomCheckerStateName,
);

const selectPetWeight = createSelector(
  selectSymptomCheckerState,
  (state) => state.petWeight,
);

const selectIsNeutered = createSelector(
  selectSymptomCheckerState,
  (state) => state.isNeutered,
);

const selectAlgorithmCategory = createSelector(
  selectSymptomCheckerState,
  (state) => state.algorithmCategoryDocId,
);

const selectAlgorithmCategoryOptions = createSelector(
  selectSymptomCheckerState,
  (state) => state.algorithmCategoryOptions,
);

const selectCurrentNode = createSelector(
  selectSymptomCheckerState,
  (state) => state.currentNode,
);

const selectSessionId = createSelector(
  selectSymptomCheckerState,
  (state) => state.sessionId,
);

const selectOutcome = createSelector(
  selectSymptomCheckerState,
  (state) => state.outcome,
);


export const symptomCheckerSelectors = {
  selectPetWeight,
  selectIsNeutered,
  selectAlgorithmCategory,
  selectAlgorithmCategoryOptions,
  selectCurrentNode,
  selectSessionId,
  selectOutcome,
};