import { createReducer, on } from '@ngrx/store';
import { offersActions } from './offers.actions';
import { OffersState } from './offers.state';

export const initialState: OffersState = {
  offers: [],
};

export const offersReducer = createReducer(
  initialState,
  on(offersActions.loadOffersSuccess, (state, { offers }): OffersState => ({
    ...state,
    offers,
  })),
);
