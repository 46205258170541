import { ProductVersion } from "@features/policies/enums/product-version.enum";
import { CoverItem } from "@interfaces/cover-item";
import { Policy } from "@interfaces/policy";
import { createAction, props } from "@ngrx/store";

const resetState = createAction('[Change Cover] Reset');

const startChangeCoverJourney = createAction(
  '[Change Cover] Start Change Cover Journey',
);

const openCopaymentModal = createAction(
  '[Change Cover] Open Copayment Modal',
  props<
    {
      policy: Policy, 
      productType: string, 
      productVersion: ProductVersion, 
      benefits: CoverItem[] 
    }>(),
);

export const changeCoverActions = {
  resetState,
  openCopaymentModal,
  startChangeCoverJourney,
};