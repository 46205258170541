import { createFeatureSelector, createSelector } from '@ngrx/store';
import {
  CommunicationPreferencesState,
  CommunicationPreferencesStateName,
} from '../state';
import {
  MarketingPreferences,
} from '@features/communication-preferences/interfaces/marketing-preferences.interface';
import {
  policyHolderSelectors,
} from '@features/account-details/store/selectors/policy-holder.selectors';

const selectCommunicationPreferencesState = createFeatureSelector<CommunicationPreferencesState>(
  CommunicationPreferencesStateName,
);

const selectCommunicationPreferences = createSelector(
  selectCommunicationPreferencesState,
  (state) => state.communicationPreferences,
);

const selectMarketingPreferences = createSelector(
  selectCommunicationPreferences,
  (state): MarketingPreferences => ({
    marketingEmail: state.marketingEmail,
    marketingPhone: state.marketingPhone,
    marketingPost: state.marketingPost,
    marketingSms: state.marketingSms,
  }),
);

const selectAcceptedEmailComms = createSelector(
  selectCommunicationPreferences,
  (state): boolean => state.documentsEmail === true,
);

const selectJoinedConsumerPanel = createSelector(
  selectCommunicationPreferences,
  (state): boolean => state.joinedConsumerPanel === true,
);

const selectCommunicationPreferencesChanged = createSelector(
  selectCommunicationPreferencesState,
  (state) => state.communicationPreferencesChanged,
);

const selectSuccess = createSelector(
  selectCommunicationPreferencesState,
  (state) => state.success,
);

const selectError = createSelector(
  selectCommunicationPreferencesState,
  (state) => state.error,
);

const selectSaving = createSelector(
  selectCommunicationPreferencesState,
  (state) => state.saving,
);

const selectLoading = createSelector(
  selectCommunicationPreferencesState,
  (state) => state.loading,
);

const selectCanEditConsumerPanel = createSelector(
  selectJoinedConsumerPanel,
  policyHolderSelectors.selectHasComplaint,
  selectAcceptedEmailComms,
  policyHolderSelectors.selectHasAnyActivePolicy,
  (
    joinedConsumerPanel,
    hasComplaint,
    acceptedEmailComms,
    hasAnyActivePolicy) => 
    ((hasComplaint && joinedConsumerPanel) || !hasComplaint) &&
    acceptedEmailComms &&
    hasAnyActivePolicy,
);

export const communicationPreferencesSelectors = {
  selectCommunicationPreferencesState,
  selectCommunicationPreferences,
  selectMarketingPreferences,
  selectCommunicationPreferencesChanged,
  selectSuccess,
  selectError,
  selectSaving,
  selectLoading,
  selectAcceptedEmailComms,
  selectJoinedConsumerPanel,
  selectCanEditConsumerPanel,
};
