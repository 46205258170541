import { NgModule } from '@angular/core';
import { EffectsModule } from '@ngrx/effects';
import { StoreModule } from '@ngrx/store';
import { CommunicationPreferencesStateName } from './state';
import { communicationPreferencesReducer } from './reducers/communication-preferences.reducer';
import { CommunicationPreferencesEffects } from './effects/communication-preferences.effects';

@NgModule({
  imports: [
    EffectsModule.forFeature([CommunicationPreferencesEffects]),
    StoreModule.forFeature(CommunicationPreferencesStateName, communicationPreferencesReducer),
  ],
})
export class CommunicationPreferencesStoreModule {}