import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root',
})
export class PublicWebsiteService {
  formatCookiePolicyLink(
    document: Document,
    elementId: string,
    baseUrl: string,
  ): void {
    const element = document.getElementById(elementId);
    const anchor = element?.getElementsByTagName('a')[0];
    if (anchor != null){
      anchor.href = baseUrl + '/cookie-policy2';
    }
  }
  addOneTrustScript()
  {
    const script = document.createElement('script');
    //Render this but only if logged in:
    //<script src="https://cdn-ukwest.onetrust.com/scripttemplates/otSDKStub.js" data-document-language="true"
    //type="text/javascript" charset="UTF-8" data-domain-script="f22aae5b-b5b0-4149-8871-86150aa82dce"></script>
    script.setAttribute("src", "https://cdn-ukwest.onetrust.com/scripttemplates/otSDKStub.js" );
    script.setAttribute("charset", "UTF-8");
    script.setAttribute("data-domain-script", "f22aae5b-b5b0-4149-8871-86150aa82dce");
    script.type = "text/javascript";
    document.head.appendChild(script);
    //Render this but only if logged in:
    //<script type="text/javascript">
    //function OptanonWrapper() { }
    //</script>
    const script2 = document.createElement('script');
    script2.type = "text/javascript";
    script2.text = "function OptanonWrapper() { }";
    document.head.appendChild(script2);
  }
}
