import { Action, ActionReducer, createReducer, on } from "@ngrx/store";
import { UserConsentState, initialUserConsentState } from "../user-consent-state";
import { userConsentActions } from "../actions/user-consent.actions";
import { changeCoverActions } from "../actions/change-cover.actions";
import { RenewalActions } from "../actions/renewal.actions";
import { coreActions } from "@core/store/actions/core.actions";


const reducer: ActionReducer<UserConsentState, Action> = createReducer(
  initialUserConsentState,
  on(
    userConsentActions.setBreeding,
    (state, { breeding }): UserConsentState => ({
      ...state,
      breeding,
    }),
  ),
  on(
    userConsentActions.setChanges,
    (state, { changes }): UserConsentState => ({
      ...state,
      changes,
    }),
  ),
  on(
    userConsentActions.setConfirmation,
    (state, { confirmation }): UserConsentState => ({
      ...state,
      confirmation,
    }),
  ),
  on(
    userConsentActions.setUkResident,
    (state, { ukResident }): UserConsentState => ({
      ...state,
      ukResident,
    }),
  ),
  on(
    userConsentActions.setExcluded,
    (state, { excluded }): UserConsentState => ({
      ...state,
      excluded,
    }),
  ),
    
  on(
    changeCoverActions.resetState,
    RenewalActions.startRenewalJourney,
    coreActions.resetStore, 
    (): UserConsentState => ({...initialUserConsentState}),
  ),
);

export const userConsentReducer = (
  state: UserConsentState,
  action: Action,
) => reducer(state, action);