import { APP_INITIALIZER, FactoryProvider } from '@angular/core';
import { OAuthErrorEvent, OAuthInfoEvent, OAuthService } from 'angular-oauth2-oidc';
import { Subject } from 'rxjs';
import { MsalErrorCodes } from '../msal-error-codes';
import { Adb2cService } from '../services/adb2c.service';
import { OAuthIdentityService } from '../services/oauth-identity.service';
import { Store } from '@ngrx/store';
import { coreActions } from '@core/store/actions/core.actions';

export const oauthInitializerProviderFactory = (
  oauthService: OAuthService,
  adb2cService: Adb2cService,
  oauthIdentityService: OAuthIdentityService,
  store: Store,
) => async () => {
  const doNothing$ = new Subject();

  oauthService.configure(oauthIdentityService.buildOauthConfig());

  await oauthService.loadDiscoveryDocument();
  oauthService.setupAutomaticSilentRefresh();
  oauthService.events.subscribe(event => {
    if (event instanceof OAuthInfoEvent  && event.type == 'logout') {
      store.dispatch(coreActions.resetStore());
    }
  });

  try {
    await oauthService.tryLogin();
    if (!oauthService.hasValidAccessToken()) {
      oauthService.initCodeFlow();
      return doNothing$.toPromise();
    }
  } catch (error) {
    if (error instanceof OAuthErrorEvent) {
      if (
        error.params &&
          (error.params as any).error_description.indexOf(
            MsalErrorCodes.ResetPassword,
          ) !== -1
      ) {
        adb2cService.navigateToResetPassword();
        return doNothing$.toPromise();
      }

      if (
        error.params &&
          (error.params as any).error_description.indexOf(
            MsalErrorCodes.ResetPasswordCancelled,
          ) !== -1
      ) {
        if (!oauthService.hasValidAccessToken()) {
          oauthService.initCodeFlow();
          return doNothing$.toPromise();
        }
        return;
      }
    }
    window.open('/assets/pages/expired-link-error-page.html', "_self");
  }

  adb2cService.clearParams();
};

export const oauthInitializerProvider: FactoryProvider = {
  provide: APP_INITIALIZER,
  useFactory: oauthInitializerProviderFactory,
  deps: [OAuthService, Adb2cService, OAuthIdentityService, Store],
  multi: true,
};

