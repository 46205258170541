import { NgModule } from '@angular/core';
import { RouterModule } from '@angular/router';
import { CommonModule } from '@angular/common';
import { FooterModule } from '../../components/footer/footer.module';
import { HeaderModule } from '../../components/header/header.module';
import { MainPageComponent } from './main-page.component';
import { NavbarContainerModule } from '../../containers/navbar-container/navbar-container.module';

@NgModule({
  imports: [
    CommonModule,
    HeaderModule,
    RouterModule,
    FooterModule,
    NavbarContainerModule,
  ],
  declarations: [MainPageComponent],
  exports: [MainPageComponent],
})
export class MainPageModule {}
