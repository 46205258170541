import { ActionReducerMap, combineReducers } from "@ngrx/store";
import { ChangeCoverState } from "../change-cover-state";
import { coverSelectionReducer } from "./cover-selection.reducer";
import { reviewCoverReducer } from "./review-cover.reducer";
import { userConsentReducer } from "./user-consent.reducer";
import { renewalReducer } from "./renewal.reducer";

export const reducers: ActionReducerMap<ChangeCoverState> = {
    coverSelection: coverSelectionReducer,
    userConsent: userConsentReducer,
    reviewCover: reviewCoverReducer,
    renewal: renewalReducer,
};
  
export const rootReducer = combineReducers(reducers);