import { HTTP_INTERCEPTORS } from '@angular/common/http';
import { ErrorHandler, ModuleWithProviders, NgModule } from '@angular/core';
import { ServerErrorsInterceptor } from './interceptors/server-errors-interceptor';
import { HubErrorsHandler } from './services/hub-errors-handler';

@NgModule({})
export class ErrorHandlingModule {
  static forRoot(): ModuleWithProviders<ErrorHandlingModule> {
    return {
      ngModule: ErrorHandlingModule,
      providers: [
        { provide: ErrorHandler, useClass: HubErrorsHandler },
        {
          provide: HTTP_INTERCEPTORS,
          useClass: ServerErrorsInterceptor,
          multi: true,
        },
      ],
    };
  }
}
