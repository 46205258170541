import { CommonModule } from '@angular/common';
import { ModuleWithProviders, NgModule } from '@angular/core';
import {
  ClientSettings,
  CLIENT_SETTINGS,
} from './tokens/client-settings.token';
import { BuildSettings, BUILD_SETTINGS } from './tokens/build-settings.token';

@NgModule({
  declarations: [],
  imports: [CommonModule],
})
export class ConfigurationModule {
  static forRoot(
    client: ClientSettings,
    build: BuildSettings,
  ): ModuleWithProviders<ConfigurationModule> {
    return {
      ngModule: ConfigurationModule,
      providers: [
        {
          provide: CLIENT_SETTINGS,
          useValue: client,
        },
        {
          provide: BUILD_SETTINGS,
          useValue: build,
        },
      ],
    };
  }
}
