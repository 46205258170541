import { Injectable } from '@angular/core';
import { Store } from '@ngrx/store';
import { Observable } from 'rxjs';
import { selectInitialised, selectIsFeatureEnabled } from '../store/feature-flag.selector';
import { filter, switchMap } from 'rxjs/operators';

@Injectable({
  providedIn: 'root',
})
export class FeatureFlagService {
  constructor(
    private store: Store,
  ) {}

  isFeatureEnabled(feature: string): Observable<boolean> {
    return this.store.select(selectInitialised).pipe(
      filter(initialised => initialised === true),
      switchMap(() => this.store.select(selectIsFeatureEnabled(feature))),
    );
  }
}
