import { Injectable } from '@angular/core';
import { createEffect } from '@ngrx/effects';
import { filter, tap } from 'rxjs/operators';
import { NavigationEnd, Router } from '@angular/router';
import { GoogleTagManagerService } from 'angular-google-tag-manager';
import { MonitoringService } from '@core/monitoring/services/monitoring.service';

@Injectable()
export class TagmanagerEffects {

    constructor(
        private router: Router,
        private gtmService: GoogleTagManagerService,
        private monitoringService: MonitoringService,
    ) {}

    acquireTokenFailure$ = createEffect(() => {
      return this.router.events.pipe(
        filter(event => event instanceof NavigationEnd),
        tap((event: NavigationEnd) => {
            this.gtmService.pushTag({
                event: 'VirtualPageView',
                pagePath: event.url,
                pageTitle: event.url,
              })
              .catch(() =>
                this.monitoringService.logException(
                  new Error('GTM pushTag error'),
                ),
              );
        },
    ))},
    { dispatch: false },
  );
}
