import { Injectable } from "@angular/core";
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { concatLatestFrom } from '@ngrx/operators';
import { map, switchMap } from "rxjs/operators";
import { Store } from "@ngrx/store";
import { symptomCheckerActions } from "../actions/symptom-checker.action";
import { PolicySelectors } from "@features/policies/store/policy.selectors";
import { JoiiService } from "@features/joii/services/joii.service";
import { symptomCheckerSelectors } from "../selectors/symptom-checker.selector";

@Injectable()
export class SymptomCheckerEffects {
  
  fetchAlgorithmCategories$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(
        symptomCheckerActions.fetchAlgorithmCategoryOptions,
      ),
      concatLatestFrom(() => [
        this.store.select(PolicySelectors.selectPolicy),
      ]),
      switchMap(([_, policy]) =>
        this.joiiService.getAlgorithmCategories(policy.policyUniqueRef).pipe(
          map((algorithmCategoryOptions) =>
            symptomCheckerActions.setAlgorithmCategoryOptions(
              {
                algorithmCategoryOptions,
              },
            )),
        ),
      ),
    );
  });
  
  requestStartSession$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(
        symptomCheckerActions.requestStartSession,
      ),
      concatLatestFrom(() => [
        this.store.select(PolicySelectors.selectPolicy),
        this.store.select(symptomCheckerSelectors.selectPetWeight),
        this.store.select(symptomCheckerSelectors.selectIsNeutered),
        this.store.select(symptomCheckerSelectors.selectAlgorithmCategory),
      ]),
      switchMap(([_, policy, weight, isNeutered, algorithmCategoryDocId]) =>
        this.joiiService.startSession(
          policy.policyUniqueRef,
          {
            weight,
            isNeutered,
            algorithmCategoryDocId,
          },
        ).pipe(
          map((startSessionResponse) =>
            symptomCheckerActions.requestStartSessionSuccess(
              {
                startSessionResponse,
              },
            )),
        ),
      ),
    );
  });

  fetchNextNode$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(
        symptomCheckerActions.fetchNextNode,
      ),
      concatLatestFrom(() => [
        this.store.select(PolicySelectors.selectPolicy),
        this.store.select(symptomCheckerSelectors.selectSessionId),
      ]),
      switchMap(([_, policy, sessionId]) =>
        this.joiiService.getNextNode(
          policy.policyUniqueRef,
          sessionId,
          _.getNextNodeRequest,
        ).pipe(
          map((response) => symptomCheckerActions.fetchNextNodeSuccess({response})),
        ),
      ),
    );
  });

  fetchPreviousNode$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(
        symptomCheckerActions.fetchPreviousNode,
      ),
      concatLatestFrom(() => [
        this.store.select(PolicySelectors.selectPolicy),
        this.store.select(symptomCheckerSelectors.selectSessionId),
      ]),
      switchMap(([_, policy, sessionId]) =>
        this.joiiService.getPreviousNode(
          policy.policyUniqueRef,
          sessionId,
        ).pipe(
          map((response) => symptomCheckerActions.fetchPreviousNodeSuccess({response})),
        ),
      ),
    );
  });

  constructor(
    private store: Store,
    private actions$: Actions,
    private joiiService: JoiiService,
  ) {}
}