import { Action, ActionReducer, createReducer, on } from "@ngrx/store";
import { SymptomCheckerState, initialSymptomCheckerState } from "../symptom-checker-state";
import { symptomCheckerActions } from "../actions/symptom-checker.action";

const reducer: ActionReducer<SymptomCheckerState, Action> = createReducer(
  initialSymptomCheckerState,
  on(
    symptomCheckerActions.setPetWeight, 
    (state,{ petWeight }): SymptomCheckerState => ({
      ...state,
      petWeight,
    }),
  ),
  
  on(
    symptomCheckerActions.setIsNeutered, 
    (state,{ isNeutered }): SymptomCheckerState => ({
      ...state,
      isNeutered,
    }),
  ),
  
  on(
    symptomCheckerActions.setAlgorithmCategoryDocId, 
    (state, { algorithmCategoryDocId }): SymptomCheckerState => ({
      ...state,
      algorithmCategoryDocId,
    }),
  ),
  
  on(
    symptomCheckerActions.setAlgorithmCategoryOptions, 
    (state, { algorithmCategoryOptions }): SymptomCheckerState => ({
      ...state,
      algorithmCategoryOptions,
    }),
  ),
  
  on(
    symptomCheckerActions.requestStartSessionSuccess, 
    (state, { startSessionResponse }): SymptomCheckerState => ({
      ...state,
      sessionId: startSessionResponse.sessionId,
      currentNode: startSessionResponse.currentNode,
    }),
  ),
  
  on(
    symptomCheckerActions.setCurrentNode, 
    (state, { currentNode }): SymptomCheckerState => ({
      ...state,
      currentNode,
    }),
  ),
  
  on(
    symptomCheckerActions.fetchAlgorithmCategoryOptions, 
    (state): SymptomCheckerState => ({
      ...state,
      algorithmCategoryOptions: null,
    }),
  ),
  
  on(
    symptomCheckerActions.fetchNextNode, 
    (state): SymptomCheckerState => ({
      ...state,
      currentNode: null,
    }),
  ),
  
  on(
    symptomCheckerActions.fetchNextNodeSuccess, 
    (state, { response }): SymptomCheckerState => ({
      ...state,
      currentNode: response.node,
      outcome: response.outcome,
    }),
  ),
  
  on(
    symptomCheckerActions.fetchPreviousNode, 
    (state): SymptomCheckerState => ({
      ...state,
      currentNode: null,
    }),
  ),
  
  on(
    symptomCheckerActions.fetchPreviousNodeSuccess, 
    (state, { response }): SymptomCheckerState => ({
      ...state,
      currentNode: response.node,
      outcome: null,
    }),
  ),
);

export const symptomCheckerReducer = (state: SymptomCheckerState, action: Action) => 
  reducer(state, action);