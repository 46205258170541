import { InjectionToken } from '@angular/core';
import { OAuthIdentity } from '@core/authentication/models/oauth-identity';
import { PublicWebsiteSettings } from './public-website-settings.token';

export interface ClientSettings {
  oAuthIdentity: OAuthIdentity,
  endpoints: {
    campaign: string;
  };
  publicWebsite: PublicWebsiteSettings;
}

export const CLIENT_SETTINGS = new InjectionToken<ClientSettings>(
  '[CONFIG] Client Settings',
);
