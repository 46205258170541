export interface Claim {
  claimSubID: number;
  status: ClaimStatus;
  policyUniqueRef: string;
  pet: string;
  condition: string;
  dateReceived: Date;
  submittedBy: string;
  amount: number;
  treatingVetPracticeName: string;
  paymentToGoTo: string;
  isRequest: boolean;
}

export enum ClaimStatus {
  Received = 'Claim received',
  AwaitingFromVet = 'Awaiting information from vet',
  AwaitingFromCustomer = 'Awaiting information from customer',
  AwaitingFromCustomerAndVet = 'Awaiting information from customer and vet',
  Abeyance = 'Abeyance',
  InProgress = 'In progress',
  Accepted = 'Accepted',
  Declined = 'Declined',
  PassedToVet = 'Passed to vet to complete',
}

export enum ClaimType {
  RepeatMed = 'Repeat Medication',
  NewClaim = 'New Claim',
  Continuation = 'Continuation',
}
