import { AlgorithmCategory } from "../interfaces/algorithm-category";
import { SymptomCheckerNode } from "../interfaces/symptom-checker-node";
import { SymptomCheckerOutcome } from "../interfaces/symptom-checker-outcome";

export const symptomCheckerStateName = 'SymptomChecker';

export interface SymptomCheckerState {
  petWeight: number;
  isNeutered: boolean;
  algorithmCategoryDocId: string;
  algorithmCategoryOptions: AlgorithmCategory[];
  sessionId: string,
  currentNode: SymptomCheckerNode;
  outcome: SymptomCheckerOutcome
}

export const initialSymptomCheckerState: SymptomCheckerState = {
  petWeight : null,
  isNeutered : null,
  algorithmCategoryDocId: null,
  algorithmCategoryOptions: null,
  sessionId: null,
  currentNode: null,
  outcome: null,
};