import { HttpClient } from '@angular/common/http';
import { Inject, Injectable } from '@angular/core';
import { BuildSettings, BUILD_SETTINGS } from '@core/configuration/tokens/build-settings.token';
import {  Store } from '@ngrx/store';
import { FeatureFlags } from '@shared/enums/feature-flags.enum';
import { selectIsFeatureEnabled } from '@shared/feature-flags/store/feature-flag.selector';
import { iif, of } from 'rxjs';
import { Observable } from 'rxjs-compat/Observable';
import { mergeMap } from 'rxjs/operators';
import { Offer } from '../interfaces/offer.interface';

@Injectable({
  providedIn: 'root',
})
export class OffersService {
  readonly masterDataAPIBaseURL = `${this.buildSettings.MASTERDATA_API}/v1`;
  readonly offersFeatureEnabled$ = this.store.select(selectIsFeatureEnabled(FeatureFlags.Offers));

  constructor(
    @Inject(BUILD_SETTINGS) private buildSettings: BuildSettings,
    private http: HttpClient,
    private store: Store,
  ) {}

  getOffers(): Observable<Offer[]> {
    return this.offersFeatureEnabled$.pipe(
      mergeMap((isEnabled) =>
        iif(
          () => isEnabled,
          this.http.get<Offer[]>(`${this.masterDataAPIBaseURL}/offers`),
          of([]),
        ),
      ),
    );
  }
}
