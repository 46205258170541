export enum PolicyStatus {
  Live = 'live',
  LiveDocsReqd = 'live - docs reqd',
  RenewalLive = 'renewal live - docs reqd',
  Renewed = 'renewed',
  Cancelled = 'cancelled',
  Void = 'void',
  NotTakenUp = 'not taken up',
  Other = 'other',
  Expired= 'expired',
}
