import { ChangeDetectionStrategy, Component } from '@angular/core';

@Component({
  selector: 'app-vet-fees-example-v2-content',
  templateUrl: './vet-fees-example-v2-content.component.html',
  styleUrls: ['./vet-fees-example-v2-content.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class VetFeesExampleV2ContentComponent {

}
