import { createSelector } from "@ngrx/store";
import { selectChangeCoverState } from "./change-cover.selector";
import { CoverSelectionSelectors } from "./cover-selection.selector";

const lowCoverProductNames = ['Super', 'Lifetime £1,000', 'Max Value', 'Basic Plus'];

const selectReviewCoverState = createSelector(
  selectChangeCoverState,
  (state) => state.reviewCover,
);

const selectPremiumRecalculation = createSelector(
  selectReviewCoverState,
  (state) => state.premiumRecalculation,
);

const selectChangeCoverJourney = createSelector(
  selectReviewCoverState,
  (state) => state.changeCoverJourney,
);

const selectShowLowCoverMessage = createSelector(
  CoverSelectionSelectors.selectChangeCoverQuote,
  (changeCoverQuote) => 
    lowCoverProductNames
      .some(lowCoverProductName => lowCoverProductName === changeCoverQuote.productName),
);

const selectLoading = createSelector(
  selectReviewCoverState,
  (state) => state.loading,
);

const selectError = createSelector(
  selectReviewCoverState,
  (state) => state.error,
);

const selectShouldExpand = createSelector(
  selectReviewCoverState,
  (state) => state.shouldExpand,
);

const selectComplete = createSelector(
  selectReviewCoverState,
  (state) => state.complete,
);

export const reviewCoverSelectors = {
  selectReviewCoverState,
  selectPremiumRecalculation,
  selectChangeCoverJourney,
  selectShowLowCoverMessage,
  selectLoading,
  selectError,
  selectShouldExpand,
  selectComplete,
};