import { HttpClient } from '@angular/common/http';
import { Inject, Injectable } from '@angular/core';
import {
  BuildSettings,
  BUILD_SETTINGS,
} from '@core/configuration/tokens/build-settings.token';
import { StaticDocument } from '@interfaces/static-document';
import { Observable } from 'rxjs';

@Injectable({
  providedIn: 'root',
})
export class StaticDocumentService {
  constructor(
    private http: HttpClient,
    @Inject(BUILD_SETTINGS) private buildSettings: BuildSettings,
  ) {}

  getAllStaticDocuments(policyUniqueRef: string): Observable<StaticDocument[]> {
    return this.http.get<StaticDocument[]>(
      `${this.buildSettings.API}/Policy/${policyUniqueRef}/StaticDocuments`,
    );
  }
}
