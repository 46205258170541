export const UserConsentStateName = 'userConsent';

export interface UserConsentState {
    ukResident: boolean;
    changes: boolean;
    excluded: boolean;
    breeding : boolean;
    confirmation: boolean;
}

export const initialUserConsentState: UserConsentState = {
    ukResident: false,
    changes: false,
    excluded: false,
    breeding: false,
    confirmation: false,
}