export const TermsTranslationKeys = {
  NewBusinessProductUpgrade: 'AUTH.POLICY.CHANGEOFCOVER.REVIEW.TERMS.NEWBUSINESS.PRODUCTUPGRADE',
  NewBusinessProductDowngrade: 'AUTH.POLICY.CHANGEOFCOVER.REVIEW.TERMS.NEWBUSINESS.PRODUCTDOWNGRADE',
  NewBusinessProductDowngradeExcessChange: 'AUTH.POLICY.CHANGEOFCOVER.REVIEW.TERMS.NEWBUSINESS.PRODUCTDOWNGRADEEXCESSCHANGE',
  NewBusinessProductUpgradeExcessChange: 'AUTH.POLICY.CHANGEOFCOVER.REVIEW.TERMS.NEWBUSINESS.PRODUCTUPGRADEEXCESSCHANGE',
  NewBusinessExcessOnly: 'AUTH.POLICY.CHANGEOFCOVER.REVIEW.TERMS.NEWBUSINESS.EXCESSONLY',
  RenewalProductUpgrade: 'AUTH.POLICY.CHANGEOFCOVER.REVIEW.TERMS.RENEWAL.PRODUCTUPGRADE',
  RenewalProductDowngrade: 'AUTH.POLICY.CHANGEOFCOVER.REVIEW.TERMS.RENEWAL.PRODUCTDOWNGRADE',
  RenewalProductDowngradeExcessChange: 'AUTH.POLICY.CHANGEOFCOVER.REVIEW.TERMS.RENEWAL.PRODUCTDOWNGRADEEXCESSCHANGE',
  RenewalProductUpgradeExcessChange: 'AUTH.POLICY.CHANGEOFCOVER.REVIEW.TERMS.RENEWAL.PRODUCTUPGRADEEXCESSCHANGE',
  RenewalExcessOnly: 'AUTH.POLICY.CHANGEOFCOVER.REVIEW.TERMS.RENEWAL.EXCESSONLY',
  MidtermProductDowngradeExcessIncrease: 'AUTH.POLICY.CHANGEOFCOVER.REVIEW.TERMS.MIDTERM.PRODUCTDOWNGRADEEXCESSINCREASE',
  MidtermProductDowngradeExcessDecrease: 'AUTH.POLICY.CHANGEOFCOVER.REVIEW.TERMS.MIDTERM.PRODUCTDOWNGRADEEXCESSDECREASE',
  MidtermProductDowngradeExcessDecreaseLifetimeAnnual: 'AUTH.POLICY.CHANGEOFCOVER.REVIEW.TERMS.MIDTERM.PRODUCTDOWNGRADEEXCESSDECREASELIFETIMEANNUAL',
  MidtermExcessIncreaseOnly: 'AUTH.POLICY.CHANGEOFCOVER.REVIEW.TERMS.MIDTERM.EXCESSONLY',
  MidtermProductDowngrade: 'AUTH.POLICY.CHANGEOFCOVER.REVIEW.TERMS.MIDTERM.PRODUCTDOWNGRADE',
}