import {
  CommunicationPreferences,
} from "@features/communication-preferences/interfaces/communication-preferences.interface";
import {
  MarketingPreferences,
} from "@features/communication-preferences/interfaces/marketing-preferences.interface";
import { ContactFormat } from "@features/communication-preferences/enums/contact-format.enum";
import {
  DocumentContactPreference,
} from "@features/communication-preferences/enums/document-contact.enum";
import { createAction, props } from "@ngrx/store";

const updateCommunicationPreferences = createAction(
  '[Communication Preferences] Update Communication Preferences',
);

const updateCommunicationPreferencesSuccess = createAction(
  '[Communication Preferences] Update Communication Preferences Success',
);

const updateCommunicationPreferencesError = createAction(
  '[Communication Preferences] Update Communication Preferences Error',
  props<{ error: string }>(),
);

const fetchCommunicationPreferences = createAction(
  '[Communication Preferences] Fetch Communication Preferences');

const fetchCommunicationPreferencesSuccess = createAction(
  '[Communication Preferences] Fetch Communication Preferences Success',
  props<{ communicationPreferences: CommunicationPreferences }>(),
);
const fetchCommunicationPreferencesFailure = createAction(
  '[Communication Preferences] Fetch Communication Preferences Failure',
);

const documentContactPreferenceChanged = createAction(
  '[Communication Preferences] Document Contact Preference Changed',
  props<{ documentContactPreference: DocumentContactPreference }>(),
);

const marketingOptionsChanged = createAction(
  '[Communication Preferences] Marketing Options Changed',
  props<{ marketingPreferences: MarketingPreferences }>(),
);

const contactFormatChanged = createAction(
  '[Communication Preferences] Contact Format Changed',
  props<{ contactFormat: ContactFormat }>(),
);

const consumerPanelOptIn = createAction(
  '[Communication Preferences] Opted In to Consumer Panel',
);

const consumerPanelOptOut = createAction(
  '[Communication Preferences] Opted Out of Consumer Panel',
);

const cancelChanges = createAction(
  '[Policy Holder] Cancel Changes',
);

export const communicationPreferencesActions = {
  fetchCommunicationPreferences,
  fetchCommunicationPreferencesSuccess,
  fetchCommunicationPreferencesFailure,
  updateCommunicationPreferences,
  updateCommunicationPreferencesSuccess,
  updateCommunicationPreferencesError,
  documentContactPreferenceChanged,
  marketingOptionsChanged,
  contactFormatChanged,
  consumerPanelOptIn,
  consumerPanelOptOut,
  cancelChanges,
};