import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FooterSitemapComponent } from './footer-sitemap.component';
import { TranslateModule } from '@ngx-translate/core';
import { FontAwesomeModule } from '@fortawesome/angular-fontawesome';

@NgModule({
  declarations: [FooterSitemapComponent],
  imports: [CommonModule, TranslateModule, FontAwesomeModule],
  exports: [FooterSitemapComponent],
})
export class FooterSitemapModule {}
