import { Injectable } from '@angular/core';
import { environment } from '../../../../environments/environment';
import { Environment } from '../models/environment';

@Injectable({
  providedIn: 'root',
})
export class EnvironmentService implements Environment {
  get config(): string {
    return environment.config;
  }

  get translations(): string {
    return environment.translations;
  }

  get production(): boolean {
    return environment.production;
  }

  get fakeBackend(): boolean {
    return environment.fakeBackend;
  }
}
