import { PolicyHolder } from '@interfaces/policyholder';

export const MockPolicyHolder: PolicyHolder = {
  title: 'Mr',
  forename: 'Paul',
  surname: 'Chuckle',
  emailAddress: 'development@animalfriends.co.uk',
  mobilePhone: '03445570300',
  phoneNumber: '03445570300',
  eveningNumber: '03445570300',
  building: '1 The Crescent',
  street: 'Sun Rise Way Amesbury',
  town: 'Salisbury',
  city: 'Birmingham',
  county: 'Wiltshire',
  postcode: 'SP4 7QA',
  dob: new Date(1981, 8, 24),
  documentsEmail: true,
  marketingEmail: true,
  marketingPhone: false,
  marketingSMS: true,
  marketingPost: true,
};

export const MockNoMarketingPolicyHolder: PolicyHolder = {
  ...MockPolicyHolder,
  documentsEmail: false,
  marketingEmail: false,
  marketingPhone: false,
  marketingSMS: false,
  marketingPost: false,
};

export const MockFullMarketingPolicyHolder: PolicyHolder = {
  ...MockPolicyHolder,
  marketingEmail: true,
  marketingPost: true,
  marketingPhone: true,
  marketingSMS: true,
  documentsEmail: true,
};
