import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { MainPageModule } from '@core/layout/pages/main-page/main-page.module';
import { ClientNumberGuard } from '@core/guards/client-number.guard';
import { OAuthModule } from 'angular-oauth2-oidc';
import { HubRouterPaths } from './hub-router.paths';

const routes: Routes = [
  {
    path: HubRouterPaths.LoggedIn,
    canActivateChild: [ClientNumberGuard],
    loadChildren: () =>
      import('./logged-in/logged-in-router.module').then(
        (m) => m.LoggedInRouterModule,
      ),
  },
  {
    path: HubRouterPaths.Error,
    loadChildren: () =>
      import('./error/error-router.module').then((m) => m.ErrorsRouterModule),
  },
  {
    path: HubRouterPaths.PaymentResult,
    loadChildren: () =>
    import('./payment-result/payment-result-router.module').then((m) => m.PaymentResultRouterModule),
  },
  {
    path: '**',
    redirectTo: HubRouterPaths.LoggedIn,
  },
];

@NgModule({
  imports: [
    MainPageModule,
    OAuthModule.forRoot(),
    RouterModule.forRoot(routes, {
      paramsInheritanceStrategy: 'always',
    }),
  ],
})
export class HubRouterModule {}
