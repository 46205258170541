import { Action, ActionReducer, createReducer, on } from "@ngrx/store";
import { CoverSelectionState, initialCoverSelectionState } from "../cover-selection-state";
import { coverSelectionActions } from "../actions/cover-selection.actions";
import { changeCoverActions } from "../actions/change-cover.actions";
import { RenewalActions } from "../actions/renewal.actions";
import { coreActions } from "@core/store/actions/core.actions";


const reducer: ActionReducer<CoverSelectionState, Action> = createReducer(
  initialCoverSelectionState,
  on(
    coverSelectionActions.setCoverType,
    (state, { coverType }): CoverSelectionState => ({
      ...state,
      coverType,
    }),
  ),

  on(
    coverSelectionActions.setProductType,
    (state, props): CoverSelectionState => ({
      ...state,
      productType: props.productType,
    }),
  ),

  on(
    coverSelectionActions.setLimitType,
    (state, props): CoverSelectionState => ({
      ...state,
      limitType: props.limitType,
    }),
  ),
    
  on(
    changeCoverActions.resetState,
    RenewalActions.startRenewalJourney,
    (): CoverSelectionState => ({...initialCoverSelectionState}),
  ),

  on(
    coverSelectionActions.setCoverProduct,
    (state, props): CoverSelectionState => ({
      ...state,
      coverProduct: props.coverProduct,
    }),
  ),
    
  on(
    coverSelectionActions.setChangeCoverQuote,
    (state, props): CoverSelectionState => ({
      ...state,
      changeCoverQuote: props.changeCoverQuote,
    }),
  ),
    
  on(
    coverSelectionActions.setExcess,
    (state, props): CoverSelectionState => ({
      ...state,
      excess: props.excess,
    }),
  ),
    
  on(
    coverSelectionActions.setCurrentCover,
    (state, props): CoverSelectionState => ({
      ...state,
      currentCover: props.currentCover,
    }),
  ),
    
  on(
    coverSelectionActions.fetchChangeCoverQuoteSuccess,
    (state, props): CoverSelectionState => ({
      ...state,
      possibleCovers: props.possibleCovers,
      excess : props.excess,
    }),
  ),
    
  on(
    coverSelectionActions.fetchCoverChangeTypeSuccess,
    (state, props): CoverSelectionState => ({
      ...state,
      coverChangeType: props.coverChangeType,
    }),
  ),

  on(
    coverSelectionActions.fetchTreatmentDataForDogSuccess,
    (state, { treatmentData }): CoverSelectionState => ({
      ...state,
      treatmentData: {
        ...state.treatmentData,
        dog: treatmentData,
      },
    }),
  ),

  on(
    coverSelectionActions.fetchTreatmentDataForCatSuccess,
    (state, { treatmentData }): CoverSelectionState => ({
      ...state,
      treatmentData: {
        ...state.treatmentData,
        cat: treatmentData,
      },
    }),
  ),
  
  on(coreActions.resetStore, 
    (): CoverSelectionState => ({
      ...initialCoverSelectionState,
    })),
);

export const coverSelectionReducer = (state: CoverSelectionState, action: Action) => 
  reducer(state, action);
