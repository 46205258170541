import { CoverSelectionState, initialCoverSelectionState } from "./cover-selection-state";
import { RenewalState, initialRenewalState } from "./renewal.state";
import { initialReviewCoverState, ReviewCoverState } from "./review-cover-state";
import { initialUserConsentState, UserConsentState } from "./user-consent-state";


export const ChangeCoverStateName = 'ChangeCover';

export interface ChangeCoverState {
  coverSelection: CoverSelectionState;
  userConsent: UserConsentState;
  reviewCover: ReviewCoverState;
  renewal: RenewalState;
}

export const initialChangeCoverState: ChangeCoverState = {
  coverSelection: initialCoverSelectionState,
  userConsent: initialUserConsentState,
  reviewCover: initialReviewCoverState,
  renewal: initialRenewalState,
};