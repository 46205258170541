import { HttpClient, HttpHeaders } from "@angular/common/http";
import { Inject, Injectable } from "@angular/core";
import { BuildSettings, BUILD_SETTINGS } from "@core/configuration/tokens/build-settings.token";
import { policyPatchResponse } from "@features/policies/interfaces/policy-patch-response.interface";
import { Observable } from "rxjs";
import { UpdateAddress } from "../components/Interfaces/update-address.interface";
import {
  CommunicationPreferences,
} from "../../communication-preferences/interfaces/communication-preferences.interface";
import { 
  ClientDetails,
} from "@features/communication-preferences/interfaces/client-details.interface";
import { ClientRestrictions } from "../interfaces/client-restrictions.interface";
import { ClientConsents } from "../interfaces/client-consents.interface";
import { ClientConsentsPatchRequest } from "../interfaces/client-consents-patch-request.interface";

@Injectable({
  providedIn: 'root',
})
export class ClientService {
  private clientsBaseURL = `${this.buildSettings.CLIENT_API}/v1`;
  private httpOptions = {
    headers: new HttpHeaders({
      'Content-Type': 'application/json',
    }),
  };

  constructor(
    private http: HttpClient,
    @Inject(BUILD_SETTINGS) private buildSettings: BuildSettings,
  ) {}
  
  getChangeAllowed(factor: string): Observable<boolean> {
    return this.http.get<boolean>(
      `${this.clientsBaseURL}/change-allowed?factor=${factor}`);
  }
  
  auditCancelledChange(
    policyUniqueRef: string,
    factor: string,
    oldValue: string,
    newValue: string,
  ): Observable<number> {
 
    return this.http.post<number>(
      `${this.clientsBaseURL}/AuditCancelledChanges`,
      [
        {
          PolicyNoLong: policyUniqueRef,
          Factor: factor,      
          OldValue : oldValue,
          NewValue : newValue,
        },
      ],      
      {
        ...this.httpOptions,
      },
    );
  } 

  auditUpdateAddressCancelledChange(updateAddress: UpdateAddress): Observable<number> {
    return this.http.post<number>(
      `${this.clientsBaseURL}/AuditCancelledChanges`,
      updateAddress,      
      {
        ...this.httpOptions,
      },
    );
  } 

  updateAddress(updateAddress: UpdateAddress){
    return this.http.patch<policyPatchResponse>(
      `${this.clientsBaseURL}/update-address`,
      {
        newAddress: updateAddress.newAddress,
        oldAddress: updateAddress.oldAddress,
      },
      {
        ...this.httpOptions,
      });
  }

  getCommunicationPreferences(): Observable<CommunicationPreferences> {
    return this.http.get<CommunicationPreferences>(
      `${this.clientsBaseURL}/communication-preferences`);
  }

  updateCommunicationPreferences(communicationPreferences: CommunicationPreferences) {
    return this.http.patch(
      `${this.clientsBaseURL}/communication-preferences`,
      communicationPreferences,
      this.httpOptions,
    );
  }

  getRestrictions(): Observable<ClientRestrictions> {
    return this.http.get<ClientRestrictions>(
      `${this.clientsBaseURL}/restrictions`);
  }
  
  getPersonalDetails(): Observable<ClientDetails> {
    return this.http.get<ClientDetails>(
      `${this.clientsBaseURL}`);
  }

  getConsents() {
    return this.http.get<ClientConsents>(
      `${this.clientsBaseURL}/consents`,
    );
  }

  patchConsents(request: ClientConsentsPatchRequest) {
    return this.http.patch<ClientConsents>(
      `${this.clientsBaseURL}/consents`,
      request,
    );
  }
}
