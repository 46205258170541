import { createSelector } from "@ngrx/store";
import { selectChangeCoverState } from "./change-cover.selector";
import { PolicySelectors } from "@features/policies/store/policy.selectors";
import { Policy } from "@interfaces/policy";
import { PolicyPaymentFrequency } from "@features/policies/enums/policy-payment-frequency.enum";
import { RenewalState } from "../renewal.state";
import { selectIsFeatureEnabled } from "@shared/feature-flags/store/feature-flag.selector";
import { FeatureFlags } from "@shared/enums/feature-flags.enum";

const selectRenewalState = createSelector(
  selectChangeCoverState,
  (state) => state.renewal,
);

const selectDismissed = createSelector(
  selectRenewalState,
  (state) => state.dismissed,
);

const selectUsingJourney = createSelector(
  selectRenewalState,
  (state) => state.usingJourney,
);

const selectJourneyComplete = createSelector(
  selectRenewalState,
  (state) => state.journeyComplete,
);

const selectChangeSummary = createSelector(
  selectRenewalState,
  (state) => state.changes,
);

const selectConfirmationRequired = createSelector(
  PolicySelectors.selectPolicy,
  selectRenewalState,
  (policy: Policy, state: RenewalState) => 
    ((policy?.paymentFrequency === PolicyPaymentFrequency.Annually && 
    !policy?.payments.hasGivenCPAConsent) || state.isConfirmationRequired),
);

const selectDiscount = createSelector(
  selectRenewalState,
  (state) => state.discount,
);

const selectEligibleForDiscount = createSelector(
  selectDiscount,
  selectIsFeatureEnabled(FeatureFlags.CancellationDiscounts),
  (discount, isDiscountFeatureEnabled) =>
    discount !== null && discount.factor !== 1 && isDiscountFeatureEnabled,
);

const selectDiscountPercent = createSelector(
  selectDiscount,
  (discount) => (100 - (discount?.factor * 100)),
);

const selectDiscountAccepted = createSelector(
  selectRenewalState,
  (state) => state.discountAccepted,
);

const selectCancellationReasons = createSelector(
  selectRenewalState,
  (state) => state.cancellationReasons,
);

const selectCancellationProviders = createSelector(
  selectRenewalState,
  (state) => state.cancellationProviders,
);

const selectIsApplyDiscountInProgress = createSelector(
  selectRenewalState,
  (state) => state.isApplyDiscountInProgress,
);

export const RenewalSelectors = {
  selectRenewalState,
  selectDismissed,
  selectUsingJourney,
  selectJourneyComplete,
  selectChangeSummary,
  selectConfirmationRequired,
  selectEligibleForDiscount,
  selectDiscountPercent,
  selectDiscount,
  selectDiscountAccepted,
  selectCancellationReasons,
  selectCancellationProviders,
  selectIsApplyDiscountInProgress,
};