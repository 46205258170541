<div class="row">
    <div class="col-8 col-sm-7 col-lg-5" translate>AUTH.POLICY.CHANGEOFCOVER.COPAYMENTSEXPLANATION.EXAMPLE.AMOUNT</div>
    <div class="col-4 col-sm-5 col-lg-7" translate>AUTH.POLICY.CHANGEOFCOVER.COPAYMENTSEXPLANATION.EXAMPLE.AMOUNTVALUE</div>
</div>
<div class="row mt-3">
    <div class="fw-bold col-8 col-sm-7 col-lg-5" translate>AUTH.POLICY.CHANGEOFCOVER.COPAYMENTSEXPLANATION.EXAMPLE.PAY</div>
</div>
<div class="row">
    <div class="col-8 col-sm-7 col-lg-5" translate>AUTH.POLICY.CHANGEOFCOVER.COPAYMENTSEXPLANATION.EXAMPLE.EXCESS</div>
    <div class="col-4 col-sm-5 col-lg-7" translate>AUTH.POLICY.CHANGEOFCOVER.COPAYMENTSEXPLANATION.EXAMPLE.EXCESSVALUE</div>
</div>
<div class="row">
    <div class="col-8 col-sm-7 col-lg-5" translate>AUTH.POLICY.CHANGEOFCOVER.COPAYMENTSEXPLANATION.EXAMPLE.COPAYMENT</div>
    <div class="col-4 col-sm-5 col-lg-7" translate>AUTH.POLICY.CHANGEOFCOVER.COPAYMENTSEXPLANATION.EXAMPLE.COPAYMENTVALUE</div>
</div>
<div class="row">
    <div class="col-8 col-sm-7 col-lg-5" translate>AUTH.POLICY.CHANGEOFCOVER.COPAYMENTSEXPLANATION.EXAMPLE.TOTAL</div>
    <div class="col-4 col-sm-5 col-lg-7" translate>AUTH.POLICY.CHANGEOFCOVER.COPAYMENTSEXPLANATION.EXAMPLE.TOTALVALUE</div>
</div>
<div class="row">
    <div class="col-8 col-sm-7 col-lg-5" translate>AUTH.POLICY.CHANGEOFCOVER.COPAYMENTSEXPLANATION.EXAMPLE.ANIMALFRIENDS</div>
    <div class="col-4 col-sm-5 col-lg-7" translate>AUTH.POLICY.CHANGEOFCOVER.COPAYMENTSEXPLANATION.EXAMPLE.ANIMALFRIENDSVALUE</div>
</div>