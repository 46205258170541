import { PublicWebsiteFaq } from '@interfaces/publicwebsite-faq';

const MockFaqQuestion1: PublicWebsiteFaq = {
  title: 'Question 1',
  content:
      '<p>If you are unhappy with the outcome of a claim, please call us on <strong>0344 557 0300</strong> to discuss your case further.</p>\n<p>Should you wish to make a complaint, please visit our <a href="/existing-customers/complaints-procedure/">complaints</a> page or more information.\t\t</p>\n',
};

const MockFaqQuestion2: PublicWebsiteFaq = {
  title: 'Question 2',
  content:
      '<p>We review your premium price on an annual basis, and there are multiple factors that may cause pricing changes, either up or down, such as claim history, your location, the average cost of vet treatment at the time, changes in tax rates etc. However, we do not offer “no claims bonuses” to our policies, as we think that is unfair to our policyholders who do need to claim. We regularly review our pricing structure to make sure we give our customers a fair deal.</p>\n',
};

const MockFaqsMap = {
  MockFaqQuestion1,
  MockFaqQuestion2,
};

export const MockFaqs: PublicWebsiteFaq[] = Object.values(MockFaqsMap);
