import { CoverType } from "@features/change-cover/enums/cover-type.enum";
import { LimitType } from "@features/change-cover/enums/limit-type.enum";
import { ProductType } from "@features/change-cover/enums/product-type.enum";
import { ChangeCoverQuote } from '@features/change-cover/interfaces/change-cover-quote';
import { CoverProducts } from "../enums/cover-products.enum";
import { CoverItem } from "@interfaces/cover-item";
import { CoverChangeType } from "../enums/cover-change-type.enum";
import { DogTreatment } from "../interfaces/dog-treatment.interface";
import { Treatment } from "../interfaces/treatment.interface";


export const CoverSelectionStateName = 'coverSelection';

export interface CoverSelectionState {
    coverType : CoverType;
    productType: ProductType;
    limitType: LimitType;
    coverProduct: CoverProducts;
    changeCoverQuote: ChangeCoverQuote;
    excess: number;
    currentCover: CoverItem[];
    possibleCovers: ChangeCoverQuote[];
    coverChangeType: CoverChangeType;
    treatmentData: {
      dog: DogTreatment[],
      cat: Treatment[]
    },
}

export const initialCoverSelectionState: CoverSelectionState = {
    coverType: null,
    limitType: null,
    productType: null,
    coverProduct: null,
    excess: null,
    changeCoverQuote: null,
    currentCover: null,
    possibleCovers: [],
    coverChangeType: null,
    treatmentData: null,
}