import { ChangeDetectionStrategy, Component, Input } from '@angular/core';

@Component({
  selector: 'app-vet-fees-example-v3-content',
  templateUrl: './vet-fees-example-v3-content.component.html',
  styleUrls: ['./vet-fees-example-v3-content.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class VetFeesExampleV3ContentComponent {
  @Input() productName: string;
}
